import { HttpError } from './http-error';

export class ProcessedError extends HttpError {
  public static predicate(status: number, body: any): boolean {
    return body?.title;
  }

  public constructor(status: number, body: any) {
    super(status, body, body.title);
    // http://stackoverflow.com/a/41429145
    Object.setPrototypeOf(this, ProcessedError.prototype);
  }
}
