import { FC, ReactNode, useCallback, useState } from 'react';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { observer } from 'mobx-react';

import { Button } from '../../../components';

import { CertificateModal } from './certificate-modal';

interface ICertificateButtonProps {
  children?: ReactNode;
  className?: string;
}

export const CertificateButton: FC<ICertificateButtonProps> = observer((props) => {
  const { children, className } = props;
  const [isShow, setIsShow] = useState<boolean>(false);

  const onClick = useCallback(() => {
    setIsShow(true);
  }, [setIsShow]);

  const onClose = useCallback(() => {
    setIsShow(false);
  }, [setIsShow]);

  return (
    <>
      <Button.Outlined startIcon={<PrintOutlinedIcon />} onClick={onClick} className={className}>
        {children}
      </Button.Outlined>
      <CertificateModal isShow={isShow} onClose={onClose} />
    </>
  );
});
