import { PackageState, PackageType, PurposeType } from '../constants';
import { IApi } from '../utils';
import { IGemology, IPackage } from '../view-models';

export class GemologyService {
  public constructor(private api: IApi) {}

  public async get(packageId: IPackage['packageId']): Promise<IGemology> {
    const res = await this.api.get({
      url: `package/${packageId}/${PurposeType.ForExpertise}`,
    });

    const data = res?.body?.worksheet?.workItems?.gemology;

    if (![PackageState.InWork].includes(res?.body?.state)) {
      return {
        ...(data ?? {}),
        isWorkCompleted: true,
      };
    }

    return data;
  }

  public async save(pack: IPackage, updated: IGemology): Promise<IGemology> {
    let res;
    switch (pack.type) {
      case PackageType.MultipleStones: {
        res = await this.api.put({
          url: `worksheet/gemology-multiplestones`,
          body: {
            packageId: pack.packageId,
            ...updated,
          },
        });
        break;
      }

      case PackageType.SingleStone: {
        res = await this.api.put({
          url: `worksheet/gemology-singlestone`,
          body: {
            packageId: pack.packageId,
            ...updated,
            defects: Array.from(updated.defects),
          },
        });
        break;
      }
    }

    const data = res?.body?.workItems?.gemology;

    return data;
  }

  public async finalize(packageId: IPackage['packageId']): Promise<IGemology> {
    const res = await this.api.put({
      url: `worksheet/gemology/finalize/${packageId}`,
    });

    const data = res?.body?.workItems?.gemology;

    return data;
  }
}
