import { PurposeType, WorkItems } from '../constants';

export function getPurposeTypeByWorkItem(item: WorkItems): PurposeType {
  switch (item) {
    case WorkItems.Engraving:
    case WorkItems.Shooting:
      return PurposeType.ForEngravingShooting;
    default:
      return PurposeType.ForExpertise;
  }
}
