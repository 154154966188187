import { ChangeEvent, FC, useCallback } from 'react';
import Chip from '@mui/material/Chip';
import { observer } from 'mobx-react';

import { Button } from '../../../../components';
import { useConfirm } from '../../../../utils';
import { IFilePair } from '../../../../view-models';

interface IAddHeliumPolishProps {
  value?: string;
  onChange: (file?: IFilePair) => void;
  onDownload?: (fileName: string) => void;
  disabled?: boolean;
}

export const HeliumPolishFile: FC<IAddHeliumPolishProps> = observer((props) => {
  const { onChange, value, disabled, onDownload } = props;
  const { confirm } = useConfirm();

  const AddFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const fileBlob: File | undefined = event.target.files?.[0];

      if (fileBlob) {
        const newData: IFilePair = {
          file: fileBlob,
          fileName: fileBlob.name,
        };

        onChange(newData);
      }
    },
    [onChange]
  );

  const onDelete = useCallback(async () => {
    const isConfirmed = await confirm({
      title: 'Удаление файла Helliun Polish',
      content: 'Подтвердите удаление файла Helliun Polish',
    });

    if (isConfirmed) {
      onChange();
    }
  }, [onChange]);

  const onClick = useCallback(() => {
    if (value) {
      onDownload?.(value);
    }
  }, [onDownload, value]);

  return (
    <div>
      {value ? (
        <Chip label={value} onClick={onClick} onDelete={!disabled ? onDelete : undefined} />
      ) : (
        <Button.Upload onChange={AddFile} disabled={disabled} multiple={false}>
          Загрузить файл
        </Button.Upload>
      )}{' '}
    </div>
  );
});
