import { ChangeEventHandler } from 'react';

import { FormFieldType, IMetadataField } from '../../../../components';

export const getMultipleStonesMetadata = (
  isGia: boolean,
  isRussian: boolean,
  isFantasyColor: boolean,
  changeIsFantasyColorHandler: ChangeEventHandler<HTMLInputElement>
): Array<IMetadataField | string> => [
  'Огранка РФ / GIA',
  {
    name: 'cutFrom',
    label: 'От',
    type: FormFieldType.SELECT,
    url: 'consul/diamond-cut-inter-system',
    rules: { required: 'Обязательно к заполнению' },
  },
  {
    name: 'cutTo',
    label: 'До',
    type: FormFieldType.SELECT,
    url: 'consul/diamond-cut-inter-system',
    rules: { required: 'Обязательно к заполнению' },
  },
  'devider',

  {
    name: 'isFantasyColor',
    label: 'Фантазийный цвет',
    type: FormFieldType.CHECKBOX,
    onChange: changeIsFantasyColorHandler,
  },
  // -------------------------------------------
  ...(isFantasyColor
    ? [
        {
          name: 'fantasyColorRu',
          label: 'Цвет РФ',
          type: FormFieldType.SELECT,
          url: 'consul/diamond-color-ru',
          rules: { required: isRussian && 'Обязательно к заполнению' },
          disabled: !isRussian,
        },
        ...(isGia
          ? [
              'Цвет GIA',
              {
                name: 'fantasyColorGia.intensity',
                label: 'Интенсивность',
                type: FormFieldType.SELECT,
                url: 'consul/diamond-fantasy-color-gia/intensity',
              },
              {
                name: 'fantasyColorGia.baseColor',
                label: 'Основной',
                type: FormFieldType.SELECT,
                url: 'consul/diamond-fantasy-color-gia/baseColors',
                rules: { required: isGia && 'Обязательно к заполнению' },
                disabled: !isGia,
              },
              {
                name: 'fantasyColorGia.shade',
                label: 'Оттенок 1',
                type: FormFieldType.SELECT,
                url: 'consul/diamond-fantasy-color-gia/shades',
              },
              {
                name: 'fantasyColorGia.shade2',
                label: 'Оттенок 2',
                type: FormFieldType.SELECT,
                url: 'consul/diamond-fantasy-color-gia/shades',
              },
            ]
          : []),
      ]
    : [
        ...(isRussian
          ? [
              'Цвет РФ',
              {
                name: 'ordinalColorRuFrom',
                label: 'От',
                type: FormFieldType.SELECT,
                url: 'consul/diamond-color-ru',
                rules: { required: isRussian && 'Обязательно к заполнению' },
                disabled: !isRussian,
              },
              {
                name: 'ordinalColorRuTo',
                label: 'До',
                type: FormFieldType.SELECT,
                url: 'consul/diamond-color-ru',
                rules: { required: isRussian && 'Обязательно к заполнению' },
                disabled: !isRussian,
              },
            ]
          : []),
        ...(isGia
          ? [
              'Цвет GIA',
              {
                name: 'ordinalColorGiaFrom',
                label: 'От',
                type: FormFieldType.SELECT,
                url: 'consul/diamond-color-gia',
                rules: { required: isGia && 'Обязательно к заполнению' },
                disabled: !isGia,
              },
              {
                name: 'ordinalColorGiaTo',
                label: 'От',
                type: FormFieldType.SELECT,
                url: 'consul/diamond-color-gia',
                rules: { required: isGia && 'Обязательно к заполнению' },
                disabled: !isGia,
              },
            ]
          : []),
      ]),

  // -------------------------------------------
  ...(isRussian
    ? [
        'devider',
        'Чистота РФ',
        {
          name: 'clarityRuFrom',
          label: 'От',
          type: FormFieldType.SELECT,
          url: 'consul/diamond-clarity-ru',
          rules: { required: isRussian && 'Обязательно к заполнению' },
          disabled: !isRussian,
        },
        {
          name: 'clarityRuTo',
          label: 'До',
          type: FormFieldType.SELECT,
          url: 'consul/diamond-clarity-ru',
          rules: { required: isRussian && 'Обязательно к заполнению' },
          disabled: !isRussian,
        },
      ]
    : []),
  ...(isGia
    ? [
        'devider',
        'Чистота GIA',
        {
          name: 'clarityGiaFrom',
          label: 'От',
          type: FormFieldType.SELECT,
          url: 'consul/diamond-clarity-gia',
          rules: { required: isGia && 'Обязательно к заполнению' },
          disabled: !isGia,
        },
        {
          name: 'clarityGiaTo',
          label: 'До',
          type: FormFieldType.SELECT,
          url: 'consul/diamond-clarity-gia',
          rules: { required: isGia && 'Обязательно к заполнению' },
          disabled: !isGia,
        },
      ]
    : []),
  'devider',
  {
    name: 'externalInfluence',
    label: 'Признаки внешнего воздействия',
    type: FormFieldType.CHECKBOX,
  },
];
