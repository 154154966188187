import lodash from 'lodash';
import { makeObservable, observable, runInAction } from 'mobx';

import { Services } from '../services';
import { IPackage, IPlotting } from '../view-models';

import { FilesStore } from './files-store';

const DefaultData: IPlotting = {
  isWorkCompleted: false,
  fileIdentity: null,
};

export class PlottingStore {
  @observable public data: IPlotting | undefined = undefined;

  public constructor(
    private services: Services,
    private filesStore: FilesStore
  ) {
    makeObservable(this);
  }

  public fetch = async (packageId: IPackage['packageId']): Promise<IPlotting> => {
    const res = await this.services.plotting.get(packageId);
    if (res?.fileIdentity) {
      await this.filesStore.fetch(`worksheet/plotting-file/${packageId}`, res.fileIdentity, true);
    }

    runInAction(() => {
      this.data = res ? observable(res) : undefined;
    });

    return this.toViewModel(res);
  };

  public save = async (packageId: IPackage['packageId'], data: IPlotting): Promise<IPlotting> => {
    const res = await this.services.plotting.save(
      packageId,
      { ...data, file: data.fileIdentity ? this.filesStore.get(data.fileIdentity) : undefined },
      this.data
    );

    runInAction(() => {
      this.data = res ? observable(res) : undefined;
    });

    return this.toViewModel(res);
  };

  public completeResearch = async (packageId: IPackage['packageId']): Promise<IPlotting> => {
    const res = await this.services.plotting.finalize(packageId);

    return this.toViewModel(res);
  };

  private toViewModel = (data: any): IPlotting => {
    return lodash.pick({ ...DefaultData, ...(data ?? {}) }, ['isWorkCompleted', 'fileIdentity']);
  };
}
