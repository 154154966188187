import { createTheme } from '@mui/material/styles';

import { theme as mainTheme } from '../../../../app/theme';

export const theme = createTheme({
  ...mainTheme,
  components: {
    ...mainTheme.components,
    MuiFormGroup: {
      styleOverrides: {
        root: () => ({
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          padding: '25px 20px',
        }),
      },
    },
  },
});
