export function minValidator(minValue: number) {
  return (v: number | null | undefined | string) => {
    // чтобы не было проверки на обязательность
    if (v === null || v === undefined || v === '') {
      return true;
    }

    return Number(v) > minValue || `Должно быть больше ${minValue}`;
  };
}
