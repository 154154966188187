import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import lodash from 'lodash';
import { action, computed, IReactionDisposer, makeObservable, observable, reaction, runInAction } from 'mobx';

import { IOAuth2DTO } from '../models';
import { Services } from '../services';
import { KeycloakAuthError } from '../services/auth/errors';
import { ICredentials, JwtToken } from '../view-models';

export const AUTH_INFO: string = 'auth-info';

dayjs.extend(durationPlugin);

export class AuthStore {
  @observable public authError: Error | null = null;
  @observable public token: any = null;
  @observable private userSession: (IOAuth2DTO & { accessToken: JwtToken; refreshToken: JwtToken }) | null = null;

  private accessTokenObserver: IReactionDisposer;

  public constructor(private services: Services) {
    makeObservable(this);

    const session = window.localStorage.getItem(AUTH_INFO);
    if (session) {
      this.setAuth(JSON.parse(session));
    }

    // Подписка на изменение storage, для того чтобы следить за аутентификацией на других вкладках
    window.addEventListener('storage', (e: StorageEvent) => {
      // Во всех нормальных браузерах (все кроме IE) данный код срабатывает, при изменении storage, в других вкладках
      // В IE же данный код сработает и на текущей вкладке
      if (e.key === AUTH_INFO) {
        if (e.newValue === null) {
          this.logout();
        } else {
          if (window.location.pathname.includes('login')) {
            window.location.replace('/');
          }
        }
      }
    });

    this.accessTokenObserver = reaction(
      () => this.userSession?.accessToken?.isExpired,
      (isExpired?: boolean) => {
        if (isExpired) {
          console.warn(`Access token истек !!!`);

          if (!this.userSession?.refreshToken.isExpired) {
            this.refreshToken();
          } else {
            console.warn(`Refresh token истек`);
            this.logout();
          }
        }
      },
      {
        fireImmediately: true, // чтобы reaction срабатывал и в первый раз
        // prev.accessToken?.isExpired будет равен next.accessToken?.isExpired т.к.
        // prev.accessToken и next.accessToken это ссылка на один и тот же объект.
        equals: (prev, next): boolean => prev === next,
      }
    );
  }

  public refreshToken(): void {
    if (this.userSession?.refresh_token) {
      this.login({ refresh_token: this.userSession?.refresh_token });
    }
  }

  public login = async (credentials: ICredentials): Promise<void> => {
    try {
      const oAuth2 = await this.services.auth.get(credentials);
      this.setAuth(oAuth2);

      if (window.location.pathname.includes('login')) {
        console.warn(oAuth2.access_token);
        window.location.replace('/');
      }
    } catch (error) {
      runInAction(() => {
        if (error instanceof TypeError) {
          throw new Error((error as TypeError).message);
        } else if (error instanceof KeycloakAuthError) {
          if (credentials.refresh_token) {
            this.logout();
          } else {
            this.authError = new Error(error.displayMessage);
          }
        } else {
          this.authError = new Error(
            (error as Error).name === 'Error' ? (error as Error).message : 'Не удалось авторизоваться'
          );
        }
      });
    }
  };

  public logout = (): void => {
    this.accessTokenObserver?.();
    window.localStorage.removeItem(AUTH_INFO);
    if (!window.location.pathname.includes('login')) {
      window.location.replace('/login');
    }
  };

  @computed
  public get isAuth(): boolean {
    return !!this.userSession;
  }

  @action
  private setAuth(oAuth2?: IOAuth2DTO) {
    if (!lodash.isEmpty(oAuth2)) {
      window.localStorage.setItem(AUTH_INFO, JSON.stringify(oAuth2));

      this.userSession = observable({
        ...oAuth2,
        accessToken: new JwtToken(oAuth2.access_token),
        refreshToken: new JwtToken(oAuth2.refresh_token),
      });
    }
  }
}

// { "id": "$1", "name": "$1" }
