import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

import { Button } from '../../../../components';

import './add-package-button.css';

interface IAddPackageButtonProps {
  orderId: string;
}

export const AddPackageButton: FC<IAddPackageButtonProps> = (props) => {
  const { orderId } = props;
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(`/orders/${orderId}/packages/create`);
  }, [navigate, orderId]);

  return (
    <Button.Outlined disabled={!orderId} onClick={onClick} startIcon={<AddIcon />} className="add-package-button">
      Пакет
    </Button.Outlined>
  );
};
