import { PackageState, PurposeType } from '../constants';
import { IApi } from '../utils';
import { IEngraving, IPackage } from '../view-models';

export class EngravingService {
  public constructor(private api: IApi) {}

  public async get(packageId: IPackage['packageId']): Promise<IEngraving> {
    const res = await this.api.get({
      url: `package/${packageId}/${PurposeType.ForEngravingShooting}`,
    });

    const data = res?.body?.worksheet?.workItems?.engraving;

    if ([PackageState.Done].includes(res?.body?.state)) {
      return {
        ...(data ?? {}),
        isWorkCompleted: true,
      };
    }

    return data;
  }

  public async finalize(packageId: IPackage['packageId']): Promise<IEngraving> {
    const res = await this.api.put({
      url: `worksheet/engraving/finalize/${packageId}`,
    });

    return res.body?.workItems?.engraving;
  }
}
