import { forwardRef, ForwardRefExoticComponent, RefAttributes, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { OutlinedInputProps } from '@mui/material/OutlinedInput/OutlinedInput';
import { observer } from 'mobx-react';

import { VisiblityButton } from '../../../../pages/login/visiblity-button';

export const FieldPassword: ForwardRefExoticComponent<Omit<OutlinedInputProps, 'ref'> & RefAttributes<unknown>> = observer(
  forwardRef(({ value, ...props }, ref) => {
    const [isShowPassword, setShowPassword] = useState(false);

    return (
      <OutlinedInput
        {...props}
        ref={ref}
        value={value ?? ''}
        type={isShowPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <VisiblityButton isShow={isShowPassword} onToggle={setShowPassword} />
          </InputAdornment>
        }
      />
    );
  })
);
