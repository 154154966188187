import { ChangeEventHandler, forwardRef, ForwardRefExoticComponent } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { observer } from 'mobx-react';

export const FieldCheckbox: ForwardRefExoticComponent<
  Omit<
    {
      value: boolean;
      disabled?: boolean;
      onChange?: ChangeEventHandler<HTMLInputElement>;
      label?: string;
    },
    'ref'
  >
> = observer(
  forwardRef((props, ref) => {
    const { disabled, label, value, ...restProps } = props;

    return (
      <FormControlLabel
        control={<Checkbox checked={value ?? false} {...restProps} />}
        label={label ?? 'Да'}
        disabled={disabled}
      />
    );
  })
);
