import { FC, MouseEvent, useCallback } from 'react';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import IconButton from '@mui/material/IconButton';

interface VisiblityButtonProps {
  isShow: boolean;
  onToggle: (state: boolean) => void;
}

export const VisiblityButton: FC<VisiblityButtonProps> = (props) => {
  const { isShow, onToggle } = props;

  const onClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      onToggle(!isShow);
    },
    [isShow, onToggle]
  );

  return (
    <IconButton aria-label="visibility" onClick={onClick}>
      {isShow ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
    </IconButton>
  );
};
