import { ChangeEvent, forwardRef, ForwardRefExoticComponent, RefAttributes, useEffect } from 'react';
import { Checkbox, FormControlLabel, FormGroup, FormGroupProps } from '@mui/material';
import { observer } from 'mobx-react';

import { DictionaryStore } from '../../../../stores/dictionary-store';
import { IDictionaryItem } from '../../../../view-models';

export const FieldCheckboxGroup: ForwardRefExoticComponent<
  Omit<
    FormGroupProps & {
      url?: string;
      store?: DictionaryStore;
      value: Set<string | number>;
      isFieldHide?: (item: IDictionaryItem) => boolean;
      disabled?: boolean;
    },
    'ref' | 'onChange'
  > &
    RefAttributes<unknown> & {
      onChange: (id: any, event: ChangeEvent<HTMLInputElement>) => void;
    }
> = observer(
  forwardRef((props, ref) => {
    const { disabled, url, store, value, isFieldHide, onChange, ...restProps } = props;

    useEffect(() => {
      store?.fetchElements(url);
    }, [url, store]);

    return (
      <FormGroup {...restProps} ref={ref}>
        {store?.dictionaries
          .get(url ?? '')
          ?.filter((item) => !isFieldHide?.(item) ?? true)
          .map((item) => {
            const id = item?.id;
            const label = item?.name;
            const isChecked = value?.has(id) ?? false;

            const style = {} as any;

            if (item.span) {
              style.gridColumn = `span ${item.span}`;
            }

            return (
              <FormControlLabel
                key={id}
                style={style}
                control={<Checkbox checked={isChecked} name={`${id}`} onChange={(event, checked) => onChange(id, event)} />}
                label={label}
                disabled={disabled}
              />
            );
          })}
      </FormGroup>
    );
  })
);
