import { FormFieldType, IMetadataField } from '../../components';
import { IPackage } from '../../view-models';

export const getSingleStoneMetadata = (additionalWorkScope?: IPackage['additionalWorkScope']): Array<IMetadataField> => {
  return [
    {
      name: 'cut',
      label: 'Вид огранки',
      type: FormFieldType.SELECT,
      url: 'consul/cut',
      rules: { required: 'Обязательно к заполнению' },
    },
    {
      name: 'weightFact',
      label: 'Вес фактический, ct',
      type: FormFieldType.FLOAT,
      rules: { required: 'Обязательно к заполнению' },
    },
    { name: 'weightPlan', label: 'Вес заявленный, ct', description: '(при расхождении)', type: FormFieldType.FLOAT },
    {
      name: 'certificationSystem',
      label: 'Система оценки',
      type: FormFieldType.CHECKBOX_GROUP,
      url: 'consul/certification-system',
      rules: { required: 'Обязательно к заполнению' },
    },
    {
      name: 'certificationDocumentType',
      label: 'Вид сертификационного документа',
      type: FormFieldType.RADIO,
      url: 'consul/certification-document-type',
      rules: { required: 'Обязательно к заполнению' },
    },
    {
      name: 'container',
      label: 'Упаковка',
      type: FormFieldType.RADIO,
      url: 'consul/container',
      rules: { required: 'Обязательно к заполнению' },
    },
    {
      name: 'additionalWorkScope',
      label: 'Дополнительные работы',
      type: FormFieldType.CHECKBOX_GROUP,
      url: 'consul/additional-work-scopes',
    },
    {
      name: 'customEngravingText',
      label: '',
      type: FormFieldType.TEXT,
      disabled: !additionalWorkScope?.has('CustomEngraving'),
      rules: { required: additionalWorkScope?.has('CustomEngraving') ? 'Обязательно к заполнению' : false },
    },
  ];
};
