import { IApi } from '../utils';

export class FilesService {
  public constructor(private api: IApi) {}

  public async get(url: string): Promise<Blob> {
    const res = await this.api.get({
      url,
    });

    return res?.body;
  }
}
