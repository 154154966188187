import { CloseButton } from './close-button';
import { ContainedButton } from './contained-button';
import { OutlinedButton } from './outlined-button';
import { UploadButton } from './upload-button';

export const Button = {
  Contained: ContainedButton,
  Outlined: OutlinedButton,
  Upload: UploadButton,
  Close: CloseButton,
};
