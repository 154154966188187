import { FC, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { WorkItems } from '../../../constants';
import { IPackage } from '../../../view-models';

import './packages.css';

interface IWorksheetProps {
  data: Pick<IPackage, 'packageId' | 'additionalWorkScope'>;
}

export const Worksheet: FC<IWorksheetProps> = (props) => {
  const {
    data: { packageId, additionalWorkScope },
  } = props;

  const type = useMemo(() => {
    let res = 0;
    if (additionalWorkScope?.has('Shooting')) {
      res = res | 1;
    }

    if (additionalWorkScope?.has('CustomEngraving') || additionalWorkScope?.has('StandardEngraving')) {
      res = res | 2;
    }

    return res;
  }, [additionalWorkScope]);

  const mapper = ['', 'Cъемка', 'Гравировка', 'Гравировка / съемка'];

  return (
    <div className="order-list__packages">
      <h2 className="order-list__packages-header">Рабочий лист</h2>
      <div className="link-block">
        <NavLink to={`/packages/${packageId}/worksheet/${WorkItems.Diagnostics}`} className="nav-link">
          Оценка
        </NavLink>
        {!!type && (
          <NavLink to={`/packages/${packageId}/worksheet/${WorkItems.Engraving}`} className="nav-link">
            {mapper[type]}
          </NavLink>
        )}
      </div>
    </div>
  );
};
