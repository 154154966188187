import { FC, useCallback, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { MobXProviderContext, observer } from 'mobx-react';

import { FormWrapper, PageWrapper, Panel, Table, UnderlinedHeader } from '../../components';
import { Stores } from '../../stores';
import { IOrder } from '../../view-models';
import { WhiteGreyTableTheme } from '../common';
import { metadata as ordersListMetadata } from '../orders-list/metadata';

import './search-page.css';

export const SearchPage: FC = observer(() => {
  const { ordersListStore: store } = useContext(MobXProviderContext) as Stores;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const value = searchParams.get('orders') ?? '';

  useEffect(() => {
    store.init(value);
    return store.finalize;
  }, [value]);

  const onDoubleClick = useCallback(
    (order: IOrder) => {
      navigate(`/orders/${order.id}`);
    },
    [navigate]
  );

  return (
    <PageWrapper>
      <UnderlinedHeader>{'Заявки'}</UnderlinedHeader>
      <FormWrapper>
        <Panel className="search-page__panel">
          {store.data?.length ? (
            <ThemeProvider theme={WhiteGreyTableTheme}>
              <Table metadata={ordersListMetadata} data={store.data} keyField="id" onDoubleClickRow={onDoubleClick} />
            </ThemeProvider>
          ) : (
            store.data && <div>Ничего не найдено. Проверьте правильность запроса</div>
          )}
        </Panel>
      </FormWrapper>
    </PageWrapper>
  );
});
