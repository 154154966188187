import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { OutlinedButton } from './outlined-button';

interface ICloseButtonProps {
  to?: string;
}

export const CloseButton: FC<ICloseButtonProps> = observer((props) => {
  const { to = '../..' } = props;
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(to);
  }, [navigate, to]);

  return <OutlinedButton onClick={onClick}>Закрыть</OutlinedButton>;
});
