import { Api } from '../utils';

import { AuthService } from './auth/auth-service';
import { CertificateService } from './certificate-service';
import { DiagnosticService } from './diagnostic-service';
import { DictionaryService } from './dictionary-service';
import { EngravingService } from './engraving-service';
import { FilesService } from './files-service';
import { GemologyService } from './gemology-service';
import { MeasurementService } from './measurement-service';
import { OrdersService } from './orders-service';
import { PackageService } from './package-service';
import { PlottingService } from './plotting-service';
import { ShootingService } from './shooting-service';

if (!process.env.REACT_APP_API_URL) {
  throw new Error('Не задан путь до сервисов (REACT_APP_API_URL)');
}

if (!process.env.REACT_APP_KK_URL) {
  throw new Error('Не задан путь до keycloak (REACT_APP_KK_URL)');
}

if (!process.env.REACT_APP_CONSUL_API_URL) {
  throw new Error('Не задан путь до consul (REACT_APP_CONSUL_API_URL)');
}

const api = new Api(process.env.REACT_APP_API_URL ?? '', true);
const authApi = new Api(process.env.REACT_APP_KK_URL ?? '');
const consulApi = new Api(process.env.REACT_APP_CONSUL_API_URL ?? '');

export const services = {
  auth: new AuthService(authApi),
  certificate: new CertificateService(api),
  diagnostic: new DiagnosticService(api),
  dictionary: new DictionaryService(consulApi, api),
  engraving: new EngravingService(api),
  files: new FilesService(api),
  gemology: new GemologyService(api),
  measurement: new MeasurementService(api),
  orders: new OrdersService(api),
  package: new PackageService(api),
  plotting: new PlottingService(api),
  shooting: new ShootingService(api),
};

export type Services = typeof services;
