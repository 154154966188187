import { IApi } from '../utils';
import { ICertificate, IPackage } from '../view-models';

export class CertificateService {
  public constructor(private api: IApi) {}

  public async get(packageId: string): Promise<ICertificate> {
    const res = await this.api.get({
      url: `package/details/${packageId}`,
    });

    return {
      documentNumber: res.body.certificationDocument?.number ?? null,
      packageState: res.body.state ?? null,
    };
  }

  public async getFile(packageId: IPackage['packageId'], forPrint?: boolean): Promise<Blob> {
    console.warn(forPrint);

    const res = await this.api.get({
      url: forPrint ? `certificate/download/${packageId}` : `certificate/download/${packageId}/view`,
    });

    return res.body;
  }

  public async save(packageId: IPackage['packageId'], documentNumber: string): Promise<void> {
    await this.api.put({
      url: `package/register-certification-document`,
      body: {
        packageId: Number(packageId),
        documentNumber,
      },
    });
  }

  public async confirmPrint(packageId: IPackage['packageId']): Promise<ICertificate> {
    const res = await this.api.put({
      url: `package/print-certification-document/${packageId}`,
    });

    return {
      documentNumber: res.body.certificationDocument?.number ?? null,
      packageState: res.body.state ?? null,
    };
  }
}
