import lodash from 'lodash';

import { HttpError } from './http-error';

export class FormError extends HttpError {
  public static predicate(status: number, body: any): boolean {
    return status === 422 && body?.errors && typeof body.errors === 'object';
  }

  public constructor(status: number, body: any) {
    const formErrors: Record<string, string> = {};
    Object.entries(body.errors).forEach(([id, value]: [string, any]) => {
      formErrors[lodash.camelCase(id)] = Array.isArray(value) ? value.join(',') : value;
    });

    super(status, formErrors, 'Произошла ошибка. Попробуйте еще раз');
    // http://stackoverflow.com/a/41429145
    Object.setPrototypeOf(this, FormError.prototype);
  }
}
