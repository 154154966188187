import { PackageState, PurposeType } from '../constants';
import { IDiagnosticDTO } from '../models';
import { ContentType, IApi } from '../utils';
import { IDiagnostic, IPackage } from '../view-models';

export class DiagnosticService {
  public constructor(private api: IApi) {}

  public async get(packageId: IPackage['packageId']): Promise<IDiagnostic> {
    const res = await this.api.get({
      url: `package/${packageId}/${PurposeType.ForExpertise}`,
    });

    const data: IDiagnosticDTO = res.body?.worksheet?.workItems?.diagnostics;

    if (![PackageState.InWork].includes(res?.body?.state)) {
      return {
        ...(data ?? {}),
        isWorkCompleted: true,
      };
    }

    return data;
  }

  public async save(packageId: IPackage['packageId'], updated: IDiagnostic, original?: IDiagnostic): Promise<IDiagnostic> {
    const existedFiles = original?.files || [];
    const currentFiles = updated.files || [];

    const filesToDelete = original?.files?.filter((f) => !currentFiles?.includes(f));
    const filesToAdd = updated.files?.filter((f) => !existedFiles.includes(f));
    let res = null;
    if (filesToDelete?.length) {
      res = await this.api.del({
        url: 'worksheet/diagnostics-files',
        body: {
          packageId,
          mineral: updated.mineral,
          comment: updated.comment,
          fileNames: filesToDelete,
        },
      });
    }

    if (filesToAdd?.length) {
      res = await this.api.put({
        url: 'worksheet/diagnostics-files',
        headers: {
          'Content-Type': ContentType.FormData,
        },
        body: {
          packageId,
          mineral: updated.mineral,
          comment: updated.comment,
          files: updated.newFiles,
        },
      });
    }

    if (!filesToAdd?.length && !filesToDelete?.length) {
      res = await this.api.put({
        url: 'worksheet/diagnostics',
        body: {
          packageId,
          mineral: updated.mineral,
          comment: updated.comment,
        },
      });
    }

    const data = res?.body?.workItems?.diagnostics ?? updated;

    return data;
  }

  public async finalize(packageId: IPackage['packageId']): Promise<IDiagnostic> {
    const res = await this.api.put({
      url: `worksheet/diagnostics/finalize/${packageId}`,
    });

    return res.body?.workItems?.diagnostics;
  }
}
