import { ReactNode } from 'react';
import * as lodash from 'lodash';
import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';

import { uuid } from '../uuid';

import { MessageType } from './constants';
import { IMessage, IMessageController } from './view-models';

interface IMessageSettings {
  duration: number;
  onClose?: () => void;
  isDebounced: boolean;
  type: MessageType;
}

export class MessageController implements IMessageController {
  @observable private stack: IObservableArray<IMessage> = observable([]);
  private timeouts = new Map<string, number>();
  private defaultSettings: Omit<IMessageSettings, 'type'> = {
    duration: 5000,
    onClose: undefined,
    isDebounced: false,
  };

  public constructor() {
    makeObservable(this);
  }

  @computed
  public get messages(): IMessage[] {
    return this.stack.filter((item) => item.isVisible);
  }

  public message = (payload: ReactNode, duration?: number, onClose?: () => void, isDebounced?: boolean): string => {
    return this.add(payload, { type: MessageType.Message, duration, onClose, isDebounced });
  };

  public error = (payload: ReactNode, duration?: number, onClose?: () => void, isDebounced?: boolean): string => {
    return this.add(payload, { type: MessageType.Error, duration, onClose, isDebounced });
  };

  public info = (payload: ReactNode, duration?: number, onClose?: () => void, isDebounced?: boolean): string => {
    return this.add(payload, { type: MessageType.Info, duration, onClose, isDebounced });
  };

  public warning = (payload: ReactNode, duration?: number, onClose?: () => void, isDebounced?: boolean): string => {
    return this.add(payload, { type: MessageType.Warning, duration, onClose, isDebounced });
  };

  public success = (payload: ReactNode, duration?: number, onClose?: () => void, isDebounced?: boolean): string => {
    return this.add(payload, { type: MessageType.Success, duration, onClose, isDebounced });
  };

  @action
  private add = (payload: ReactNode, settings: Partial<IMessageSettings>): string => {
    // lodash.merge важен так как он не сливает со второго объекта поля со значением undefined
    const { type, onClose, isDebounced, duration } = lodash.merge({}, this.defaultSettings, settings);

    const newElement: IMessage = observable({
      isVisible: true,
      key: uuid(),
      payload,
      type: type ?? MessageType.Message,
      onHide: undefined,
      payloadString: JSON.stringify(payload),
    });
    if (onClose !== null) {
      newElement.onHide = () => this.hide(newElement, onClose);
    }

    const duplicate =
      isDebounced &&
      this.stack.find((item) => item.payloadString === newElement.payloadString && item.type === type && item.isVisible);
    if (duplicate) {
      this.hide(duplicate);
    }

    this.stack.push(newElement);

    if (duration > 0) {
      this.timeouts.set(
        newElement.key,
        window.setTimeout(() => this.hide(newElement, onClose), duration)
      );
    }

    return newElement.key;
  };

  @action
  private hide = (element: IMessage, onClose?: () => void): void => {
    onClose?.();
    element.isVisible = false;
    this.timeouts.delete(element.key);
  };

  @action
  public close = (key: string): null => {
    const element = this.stack.find((item) => item.key === key);
    if (element) {
      if (element.onHide) {
        // Чтобы вызывать onClose который был передан при вызове данного сообщения
        element.onHide();
      } else {
        this.hide(element);
      }
    }

    return null;
  };

  public setDefaultSettings = (settings: Partial<Omit<IMessageSettings, 'type'>>): void => {
    this.defaultSettings = { ...this.defaultSettings, ...settings };
  };
}

export const message = new MessageController();
