import { services } from '../services';

import { AuthStore } from './auth-store';
import { CertificateStore } from './certificate-store';
import { DiagnosticStore } from './diagnostic-store';
import { DictionaryStore } from './dictionary-store';
import { EngravingStore } from './engraving-store';
import { FilesStore } from './files-store';
import { GemologyStore } from './gemology-store';
import { MeasurementStore } from './measurement-store';
import { OrderFormStore } from './order-form-store';
import { OrdersListStore } from './orders-list-store';
import { PackageStore } from './package-store';
import { PackagesListStore } from './packages-list-store';
import { PlottingStore } from './plotting-store';
import { ShootingStore } from './shooting-store';

const filesStore = new FilesStore(services);

export const stores = {
  authStore: new AuthStore(services),
  certificateStore: new CertificateStore(services),
  diagnosticStore: new DiagnosticStore(services, filesStore),
  dictionaryStore: new DictionaryStore(services),
  engravingStore: new EngravingStore(services),
  filesStore,
  gemologyStore: new GemologyStore(services),
  measurementStore: new MeasurementStore(services, filesStore),
  orderFormStore: new OrderFormStore(services),
  ordersListStore: new OrdersListStore(services),
  packageStore: new PackageStore(services),
  packagesListStore: new PackagesListStore(services),
  plottingStore: new PlottingStore(services, filesStore),
  shootingStore: new ShootingStore(services, filesStore),
};

export type Stores = typeof stores;
