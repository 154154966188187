import lodash from 'lodash';

export const transformNumberFields = (data: any, numberFields: string[]): any => {
  const clone = lodash.cloneDeep(data);
  numberFields.forEach((fieldName: string) => {
    const value = lodash.get(clone, fieldName);
    if (typeof value === 'string') {
      lodash.set(clone, fieldName, value ? Number(value) : null);
    }
  });

  return clone;
};
