import { FC } from 'react';
import { Link } from 'react-router-dom';
import MaterislBreadcrumbs from '@mui/material/Breadcrumbs';

import './breadcrumbs.css';

interface BreadcrumbsItem {
  label: string;
  href: string;
}

interface IProps {
  className?: string;
  data: BreadcrumbsItem[];
}

export const Breadcrumbs: FC<IProps> = (props) => {
  const { className, data } = props;

  return (
    <MaterislBreadcrumbs separator="/" className={`breadcrumbs ${className}`}>
      {data.map((item: BreadcrumbsItem) => (
        <Link key={item.href} to={item.href} className={'breadcrumbs__link'}>
          {item.label}
        </Link>
      ))}
    </MaterislBreadcrumbs>
  );
};
