import { FC } from 'react';
import { Link } from 'react-router-dom';

import './card.css';

export interface ICardProps {
  index: number;
  name: string;
  href: string;
}

export const Card: FC<ICardProps> = (props) => {
  const { name, href, index } = props;

  return (
    <Link to={href} className="card">
      <span className="card__index">
        <span>{index}</span>
        <svg viewBox="0 0 75 75" className="card__oktagon">
          <path d="m5,22 18,-18 28,0 18,18 0,28 -18,18, -28,0 -18,-18z" stroke="#367957" strokeWidth="2" fill="transparent" />
        </svg>
      </span>
      <h1 className="card__title">{name}</h1>
    </Link>
  );
};
