import dayjs from 'dayjs';

import { ITableMetadata } from '../../components';
import { PackageState, stateMap } from '../../constants';
import { IOrder, IPackage } from '../../view-models';

export const metadata: ITableMetadata[] = [
  {
    header: 'ДАТА',
    fieldName: 'date',
    width: '110px',
    template: (data, props) => {
      let value = data[props.fieldName];
      if (value && !dayjs.isDayjs(value)) {
        value = dayjs(value);
      }

      return value.format('DD.MM.YYYY');
    },
  },
  {
    header: 'НОМЕР',
    fieldName: 'number',
    width: '238px',
    align: 'right',
  },
  {
    header: 'КЛИЕНТ',
    fieldName: 'client.name',
    width: '238px',
    align: 'right',
  },
  {
    header: 'ПРОГРЕСС',
    fieldName: 'progress',
    width: '110px',
    align: 'right',
    template: (data: IOrder) => {
      const packsCount = data.packages?.length ?? 0;
      const doneCount = data.packages?.filter(({ state }: Pick<IPackage, 'state'>) => state === PackageState.Done).length ?? 0;
      return `${doneCount}/${packsCount}`;
    },
  },
  {
    header: 'СТАТУС',
    fieldName: 'state',
    width: '110px',
    align: 'right',
    template: (data, props) => {
      const state = data[props.fieldName];
      return stateMap.get(state) ?? state;
    },
  },
];
