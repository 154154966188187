import lodash from 'lodash';
import { makeObservable, observable, runInAction } from 'mobx';

import { Services } from '../services';
import { IPackage, IShooting } from '../view-models';

import { FilesStore } from './files-store';

export class ShootingStore {
  @observable public data: IShooting | undefined = undefined;

  public constructor(
    private services: Services,
    private filesStore: FilesStore
  ) {
    makeObservable(this);
  }

  public fetch = async (packageId: IPackage['packageId']): Promise<IShooting> => {
    const res = await this.services.shooting.get(packageId);

    if (res?.mainPicture) {
      await this.filesStore.fetch(`worksheet/shooting-main-media/${packageId}`, res?.mainPicture, true);
    }

    runInAction(() => {
      this.data = res ? observable(res) : undefined;
    });

    return this.toViewModel(res);
  };

  public save = async (packageId: IPackage['packageId'], data: IShooting): Promise<IShooting> => {
    const res = await this.services.shooting.save(
      packageId,
      {
        ...data,
        mainFile: data.mainPicture ? this.filesStore.get(data.mainPicture) : undefined,
        otherFiles: (data?.otherMedia?.length ?? 0) > 0 ? data.otherMedia?.map((file) => this.filesStore.get(file)) : undefined,
      },
      this.data
    );

    runInAction(() => {
      this.data = res ? observable(res) : undefined;
    });

    return this.toViewModel(res);
  };

  public completeResearch = async (packageId: IPackage['packageId']): Promise<IShooting> => {
    const res = await this.services.shooting.finalize(packageId);

    return this.toViewModel(res);
  };

  private toViewModel = (data: any): IShooting => {
    const DefaultData: IShooting = {
      isWorkCompleted: false,
      mainPicture: null,
      otherMedia: null,
    };

    return lodash.pick({ ...DefaultData, ...(data ?? {}) }, ['isWorkCompleted', 'mainPicture', 'otherMedia']);
  };
}
