import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { MobXProviderContext, observer } from 'mobx-react';

import {
  Button,
  Form,
  FormButtons,
  FormFieldType,
  FormWrapper,
  InfoBlock,
  PageWrapper,
  Panel,
  Placeholder,
  UnderlinedHeader,
} from '../../components';
import { OrderState, PackageState, stateMap } from '../../constants';
import { Stores } from '../../stores';
import { message, useConfirm } from '../../utils';
import { IOrder } from '../../view-models';
import { Packages } from '../orders-list/packages';

import './order-form.css';

export const OrderForm: FC = observer(() => {
  const { orderFormStore: store, dictionaryStore } = useContext(MobXProviderContext) as Stores;
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { confirm } = useConfirm();

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<Partial<IOrder>>({
    values: {},
  });
  const clientType = watch('clientType');
  const state = watch('state');
  const packages = watch('packages');

  const isAllPackagesPrepared = useMemo(
    () =>
      packages?.every((pack) =>
        ([PackageState.CertificatePrinted, PackageState.Done] as Array<PackageState | null | undefined>).includes(pack.state)
      ) ?? true,
    [packages]
  );

  const isCreateMode: boolean = useMemo(() => orderId === 'create', [orderId]);
  const isEditMode: boolean = useMemo(() => !!orderId && orderId !== 'create', [orderId]);
  const isOrderDone: boolean = useMemo(() => state === OrderState.Done, [state]);

  const changeClientTypeHandler = useCallback(
    (event: any) => {
      setValue(event.target.name, event.target.value, { shouldValidate: true, shouldDirty: true });
      setValue('clientId', undefined, { shouldDirty: true });
    },
    [setValue]
  );

  const metadata = useMemo(
    () => [
      { name: 'date', label: 'Дата прихода', type: FormFieldType.DATE, rules: { required: 'Обязательно к заполнению' } },
      {
        name: 'clientType',
        label: 'Тип клиента',
        type: FormFieldType.RADIO,
        url: 'consul/client-type',
        rules: { required: 'Обязательно к заполнению' },
        onChange: changeClientTypeHandler,
      },
      {
        name: 'clientId',
        label: 'Клиент',
        placeholder: 'Выберите значение',
        type: FormFieldType.SELECT,
        url: clientType ? `clients?clientType=${clientType}` : undefined,
        rules: { required: 'Обязательно к заполнению' },
        disabled: !clientType,
      },
      {
        name: 'number',
        label: 'Номер заявки или доп. соглашения',
        placeholder: 'Введите номер',
        type: FormFieldType.TEXT,
        rules: { required: 'Обязательно к заполнению' },
      },
    ],
    [clientType, changeClientTypeHandler]
  );

  const prepareData = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all(metadata.filter(({ url }) => url).map(({ url }) => dictionaryStore.fetchElements(url)));

      await Promise.all(
        dictionaryStore.dictionaries
          .get('consul/client-type')
          ?.map((value) => dictionaryStore.fetchElements(`clients?clientType=${value.id}`)) ?? []
      );

      if (isEditMode && orderId) {
        const order = await store.fetch(orderId);
        reset(order);
      } else if (isCreateMode) {
        const clientType = dictionaryStore.getFirstValue('consul/client-type');
        reset({
          clientType: `${clientType}`,
          clientId: undefined,
          number: undefined,
          date: undefined,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, isEditMode, orderId, dictionaryStore]);

  useEffect(() => {
    prepareData();
  }, [store, prepareData, reset]);

  const onSubmit: SubmitHandler<Partial<IOrder>> = useCallback(
    async (order) => {
      let orderId = order?.id;
      if (isEditMode) {
        const isConfirmed = await confirm({
          title: 'Подтвердите',
          content: 'Вы уверены, что хотите изменить свойства заявки?',
        });

        if (!isConfirmed) {
          return;
        }

        await store.update(order);
        message.success('Заявка успешно сохранена');
      } else {
        orderId = await store.create(order);
        message.success('Заявка успешно зарегистрирована!');
      }

      navigate(`/orders/${orderId}`, { replace: true });
    },
    [store, isEditMode]
  );

  const headerText = useMemo(() => {
    if (isCreateMode) {
      return 'Новая заявка';
    }

    return `Заявка № ${store.data?.number ?? ''}`;
  }, [isCreateMode, store.data?.number]);

  const stateInfo = useMemo(() => {
    return [
      {
        label: 'Статус',
        value: stateMap.get(state ?? OrderState.New),
      },
    ];
  }, [state]);

  const onCloseOrder = useCallback(async () => {
    if (orderId) {
      const isConfirmed = await confirm({
        title: 'Закрытие заявки',
        content: (
          <pre>
            Вы уверены, что хотите закрыть заявку?
            {packages?.some(({ state }) => state !== PackageState.Done) && (
              <>
                <br />
                Не все пакеты этой заявки еще закрыты.
              </>
            )}
            <br />
            После закрытия заявку нельзя будет изменить.
          </pre>
        ),
      });

      if (!isConfirmed) {
        return;
      }

      const order = await store.completeOrder(orderId);
      reset(order);
      message.success('Заявка успешно закрыта!');
    }
  }, [orderId, store, reset, packages]);

  let body = null;

  if (isLoading) {
    body = <Placeholder className="order-list__placeholder" isLoading />;
  } else if (isCreateMode || store.data) {
    body = (
      <Form metadata={metadata} control={control} store={dictionaryStore} errors={errors} disabled={isOrderDone}>
        <FormButtons>
          <Button.Close to={'..'} />
          {!isOrderDone && (
            <Button.Contained onClick={handleSubmit(onSubmit)} disabled={!isDirty}>
              Сохранить
            </Button.Contained>
          )}
          {!isCreateMode && (
            <Button.Outlined
              startIcon={<FileDownloadOutlinedIcon />}
              onClick={store.downloadDocuments}
              disabled={isCreateMode || !packages?.length}
            >
              Документы
            </Button.Outlined>
          )}

          {isAllPackagesPrepared && state && state !== OrderState.Done && (
            <Button.Outlined onClick={onCloseOrder}>Закрыть заявку</Button.Outlined>
          )}
        </FormButtons>
      </Form>
    );
  }

  return (
    <PageWrapper>
      <UnderlinedHeader>{headerText}</UnderlinedHeader>
      <FormWrapper>
        {isEditMode && <InfoBlock data={stateInfo} />}
        <Panel className="order-form__panel">{body}</Panel>
        {isEditMode && <Packages />}
      </FormWrapper>
    </PageWrapper>
  );
});
