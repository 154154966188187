export const metadata = [
  {
    name: 'minDiameterMm',
    label: 'Диаметр Dmin, мм',
  },
  {
    name: 'maxDiameterMm',
    label: 'Диаметр Dmax, мм',
  },
  {
    name: 'totalHeightMm',
    label: 'Высота H, мм',
  },
  {
    name: 'tableSizePt',
    label: 'Размер площадки в %',
  },
  {
    name: 'crownHeightPt',
    label: 'Высота короны в %',
  },
  {
    name: 'pavilionDepthPt',
    label: 'Глубина павильона в %',
  },
  {
    name: 'girdleThicknessPt',
    label: 'Высота рундиста, %',
  },
  {
    name: 'cutRaw',
    label: 'Огранка',
  },
  {
    name: 'symmetryRaw',
    label: 'Симметрия',
  },
];
