import { action, makeObservable, observable, runInAction } from 'mobx';

import { PurposeType } from '../constants';
import { Services } from '../services';
import { IPackage } from '../view-models';

export class PackageStore {
  @observable public data: IPackage | null = null;
  @observable public isLoading: boolean = false;

  public constructor(private services: Services) {
    makeObservable(this);
  }

  @action
  public fetch = async (packageId?: IPackage['packageId'], purposeType?: PurposeType): Promise<void> => {
    if (!packageId) {
      console.warn('Не задан packageId');
      return;
    }

    const res = await this.services.package.getById(packageId, purposeType);
    runInAction(() => {
      this.data = observable(res);
    });
  };

  @action
  public finalize = (): void => {
    this.data = null;
  };

  public create = async (requestId: string, newPackage: Partial<IPackage>): Promise<IPackage['packageId'] | null> => {
    const res = await this.services.package.create(requestId, newPackage);
    return res;
  };

  public update = async (updatedPackage: Partial<IPackage>): Promise<void> => {
    await this.services.package.update(updatedPackage);
    this.fetch(updatedPackage.packageId);
  };

  public completePackage = async (packageId: IPackage['packageId']): Promise<IPackage> => {
    return await this.services.package.complete(packageId);
  };
}
