import { FC, useContext } from 'react';
import { createTheme, Modal as MuiModal, Theme, ThemeProvider } from '@mui/material';
import { MobXProviderContext, observer } from 'mobx-react';

import { Stores } from '../../stores';
import { modalTheme } from '../confirmation-dialog/modal-theme';

interface IImageViewerFullScreenProps {
  fileName: string;
  onHide: () => void;
}

export const ImageViewerFullScreen: FC<IImageViewerFullScreenProps> = observer((props) => {
  const { fileName, onHide } = props;
  const { filesStore: store } = useContext(MobXProviderContext) as Stores;
  const file = store.get(fileName);

  return file ? (
    <ThemeProvider
      theme={(theme: Theme) =>
        createTheme({
          ...theme,
          components: {
            ...theme.components,
            ...modalTheme.components,
          },
        })
      }
    >
      <MuiModal open={!!fileName}>
        <div className="image-viewer__center" onClick={onHide}>
          <img className={'image-viewer__image'} id={`img-${fileName}`} alt={fileName} src={URL.createObjectURL(file)} />
        </div>
      </MuiModal>
    </ThemeProvider>
  ) : null;
});
