import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { createTheme, Modal as MuiModal, Theme, ThemeProvider } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import cn from 'classnames';
import lodash from 'lodash';
import { reaction } from 'mobx';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button, Form, FormButtons, FormFieldType, IMetadataField } from '../../../components';
import { PackageState, PurposeType } from '../../../constants';
import { Stores } from '../../../stores';
import { useConfirm } from '../../../utils';
import { ICertificate } from '../../../view-models';

import { modalTheme } from './modal-theme';

import './certificate-modal.css';

export interface ICertificateModalProps {
  className?: string;
  isShow?: boolean;
  onClose: () => void;
}

export const CertificateModal: FC<ICertificateModalProps> = observer((props) => {
  const { certificateStore: store, packageStore } = useContext(MobXProviderContext) as Stores;
  const { className, isShow, onClose } = props;
  const { confirm } = useConfirm();
  const { packageId } = useParams();

  const [isConfirmPrinting, setIsConfirmPrinting] = useState(false);
  const [isLoadingPrintFile, setIsLoadingPrintFile] = useState(false);
  const [isLoadingViewFile, setIsLoadingViewFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setError,
    clearErrors,
    reset,
    watch,
  } = useForm<Partial<ICertificate>>({
    values: {},
  });

  const packageState = watch('packageState');

  const isPrinted = useMemo(
    () => [PackageState.CertificatePrinted, PackageState.Done].includes(packageState ?? PackageState.InWork),
    [packageState]
  );

  const loadFormData = useCallback(
    async (packageId?: string) => {
      if (packageId) {
        try {
          setIsLoading(true);
          const data = await store.fetch(packageId);
          reset(data);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [reset, packageId, setIsLoading]
  );

  useEffect(() => {
    loadFormData(packageId);

    return reset;
  }, [loadFormData, packageId, reset]);

  useEffect(() => {
    const errorObserver = reaction(
      () => store.errors,
      (errors) => {
        if (errors) {
          Object.entries(errors).forEach(([key, value]) => {
            setError(key as 'documentNumber', {
              type: 'custom',
              message: value,
            });
          });
        }
      },
      {
        fireImmediately: true, // чтобы reaction срабатывал и в первый раз
        equals: (prev?: Record<string, string>, next?: Record<string, string>): boolean => lodash.isEqual(prev, next),
        delay: 500,
      }
    );

    return () => {
      errorObserver?.();
      store.finalize();
    };
  }, [packageStore, store, reset, setError, clearErrors]);

  const onSave: SubmitHandler<Partial<any>> = useCallback(
    async (data) => {
      if (packageId) {
        try {
          setIsSaving(true);
          const isConfirmed = await confirm({});

          if (!isConfirmed) {
            return;
          }

          await store.save(packageId, data.documentNumber);
          reset(data);
          packageStore.fetch(packageId, PurposeType.ForExpertise);
        } finally {
          setIsSaving(false);
        }
      }
    },
    [store, packageId]
  );

  const onClickForPrint = useCallback(async () => {
    if (packageId) {
      try {
        setIsLoadingPrintFile(true);
        await store.print(packageId, true);
      } finally {
        setIsLoadingPrintFile(false);
      }
    }
  }, [store, packageId]);

  const onClickForView = useCallback(async () => {
    if (packageId) {
      try {
        setIsLoadingViewFile(true);
        await store.print(packageId, false);
      } finally {
        setIsLoadingViewFile(false);
      }
    }
  }, [store, packageId]);

  const onConfirmPrint = useCallback(async () => {
    if (packageId) {
      try {
        setIsConfirmPrinting(true);
        const isConfirmed = await confirm({});

        if (!isConfirmed) {
          return;
        }

        const data = await store.confirmPrint(packageId);
        reset(data);
        packageStore.fetch(packageId);
      } finally {
        setIsConfirmPrinting(false);
      }
    }
  }, [store, packageId]);

  const metadata: IMetadataField[] = useMemo(
    () => [
      {
        name: 'documentNumber',
        type: FormFieldType.CUSTOM,
        className: 'certificate-modal__body',
        template: (props) => (
          <>
            <label className="certificate-modal__label">
              {!isPrinted ? 'Введите номер сертификационного документа' : 'Номер сертификационного документа'}{' '}
            </label>
            <OutlinedInput {...props} />
          </>
        ),
        rules: {
          required: 'Обязательно к заполнению',
          validate: {
            only10digits: (v: string) => {
              return `${v}`.length === 10 || 'Номер должен иметь 10 знаков';
            },
            justSigits: (v: string) => {
              return !`${v}`.match(/\D/) || 'Номер должен состоять только из цифр';
            },
          },
        },
      },
    ],
    [isPrinted]
  );

  return (
    <ThemeProvider
      theme={(theme: Theme) =>
        createTheme({
          ...theme,
          components: {
            ...theme.components,
            ...modalTheme.components,
          },
        })
      }
    >
      <MuiModal open={isShow ?? false} onClose={onClose}>
        <div className={cn('certificate-modal__box', className)}>
          <div className="certificate-modal__header ">{!isPrinted ? 'Печать сертификата' : 'Просмотр сертификата'}</div>
          <Form
            metadata={metadata}
            control={control}
            errors={errors}
            className="certificate-modal__form"
            isLoading={isLoading}
            disabled={isPrinted}
          />
          <FormButtons className={'certificate-modal__buttons-block'}>
            <Button.Outlined onClick={onClose}>Закрыть</Button.Outlined>
            {!isPrinted && (
              <Button.Contained onClick={handleSubmit(onSave)} disabled={!isDirty} isLoading={isSaving}>
                Сохранить
              </Button.Contained>
            )}
            <Button.Outlined
              onClick={onClickForPrint}
              disabled={lodash.isNil(packageStore.data?.certificationDocument?.number)}
              isLoading={isLoadingPrintFile}
            >
              Версия для печати
            </Button.Outlined>
            <Button.Outlined
              onClick={onClickForView}
              disabled={lodash.isNil(packageStore.data?.certificationDocument?.number)}
              isLoading={isLoadingViewFile}
            >
              Версия для просмотра
            </Button.Outlined>
            {!isPrinted && (
              <Button.Outlined
                isLoading={isConfirmPrinting}
                onClick={onConfirmPrint}
                disabled={lodash.isNil(packageStore.data?.certificationDocument?.number)}
              >
                Напечатано
              </Button.Outlined>
            )}
          </FormButtons>
        </div>
      </MuiModal>
    </ThemeProvider>
  );
});
