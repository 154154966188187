import { FC, ReactNode } from 'react';

import './underlined-header.css';

interface IProps {
  children: ReactNode;
}

export const UnderlinedHeader: FC<IProps> = (props) => {
  const { children } = props;

  return <h1 className="underlined-header">{children}</h1>;
};
