import { FC, ReactNode } from 'react';
import cn from 'classnames';

import './placeholder.css';

interface IPlaceholderProps {
  children?: ReactNode;
  className?: string;
  isLoading?: boolean;
}

export const Placeholder: FC<IPlaceholderProps> = (props) => {
  const { children, className, isLoading } = props;

  return <div className={cn(className, 'placeholder', isLoading && 'placeholder_loading')}>{children ?? `Загрузка ...`}</div>;
};
