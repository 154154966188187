import { FormFieldType } from '../../../../components';
import { minValidator } from '../../../../utils';

export const metadata = [
  {
    name: 'minDiameterMm',
    label: 'Диаметр Dmin',
    type: FormFieldType.FLOAT,
    rules: {
      required: 'Обязательно к заполнению',
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 100,
        message: 'Макс: 100',
      },
    },
  },
  {
    name: 'maxDiameterMm',
    label: 'Диаметр Dmax',
    type: FormFieldType.FLOAT,
    rules: {
      required: 'Обязательно к заполнению',
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 100,
        message: 'Макс: 100',
      },
    },
  },
  {
    name: 'minHeightMm',
    label: 'Высота Hmin',
    type: FormFieldType.FLOAT,
    rules: {
      required: 'Обязательно к заполнению',
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 100,
        message: 'Макс: 100',
      },
    },
  },
  {
    name: 'maxHeightMm',
    label: 'Высота Hmax',
    type: FormFieldType.FLOAT,
    rules: {
      required: 'Обязательно к заполнению',
      validate: {
        min: minValidator(0),
      },
      max: {
        value: 100,
        message: 'Макс: 100',
      },
    },
  },
];
