import { FC } from 'react';
import lodash from 'lodash';

import { metadata } from './hellium-polish-metadata';

import './hellium-polish-grid.css';

interface InfoBlockItem {
  label: string;
  name: string;
}

interface IProps {
  className?: string;
  data?: any;
}

export const HelliumPolishGrid: FC<IProps> = (props) => {
  const { className, data } = props;

  return (
    <div className={`hellium-polish-grid ${className}`}>
      {metadata.map((item: InfoBlockItem) => (
        <div key={item.label} className="hellium-polish-grid__block">
          <div className="hellium-polish-grid__label">{item.label}</div>
          <div className="hellium-polish-grid__value">{lodash.get(data, item.name, '-')}</div>
        </div>
      ))}
    </div>
  );
};
