import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button, Form, FormButtons, FormFieldType, ImageViewerFullScreen, IMetadataField } from '../../../../components';
import { CloseButton } from '../../../../components/buttons/close-button';
import { PurposeType } from '../../../../constants';
import { Stores } from '../../../../stores';
import { downloadFile, message, useConfirm } from '../../../../utils';
import { IShooting } from '../../../../view-models';

import { ShootingFiles } from './shooting-files';

export const Shooting: FC = observer(() => {
  const { shootingStore: store, filesStore, packageStore } = useContext(MobXProviderContext) as Stores;
  const { confirm } = useConfirm();
  const { packageId } = useParams();

  const [isFinishing, setIsFinishing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [fullScreenFile, setFullScreenFile] = useState<string | null>(null);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<Partial<IShooting>>({
    values: {},
  });

  const isWorkCompleted = watch('isWorkCompleted');
  const otherMedia = watch('otherMedia');

  const loadFormData = useCallback(
    async (packageId?: string) => {
      if (packageId) {
        try {
          setIsLoading(true);
          const data = await store.fetch(packageId);
          reset(data);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [reset, packageId, setIsLoading]
  );

  useEffect(() => {
    loadFormData(packageId);

    return reset;
  }, [loadFormData, packageId, reset]);

  const onChange = useCallback(
    (data: IShooting) => {
      setValue('mainPicture', data.mainPicture, { shouldDirty: true });
      setValue('otherMedia', data.otherMedia, { shouldDirty: true });
    },
    [setValue]
  );

  const onClickFile = useCallback(
    async (fileName: string) => {
      await filesStore.fetch(`worksheet/shooting-other-media/${packageId}/${fileName}`, fileName);
      if (fileName.match(/\.(png|jpeg|jpg)$/)) {
        setFullScreenFile(fileName);
      } else {
        const blob = filesStore.get(fileName);
        if (blob) {
          downloadFile(blob, fileName);
        }
      }
    },
    [filesStore, packageId, setFullScreenFile]
  );

  const onHide = useCallback(() => {
    setFullScreenFile(null);
  }, [setFullScreenFile]);

  const metadata: IMetadataField[] = useMemo(
    () => [
      {
        name: 'mainPicture',
        label: 'Съемка',
        type: FormFieldType.CUSTOM,
        template: (props) => (
          <ShootingFiles
            value={props.value}
            onChange={onChange}
            disabled={isWorkCompleted}
            otherMedia={otherMedia}
            onClick={onClickFile}
          />
        ),
      },
    ],
    [onChange, otherMedia]
  );

  const onSubmit: SubmitHandler<Partial<IShooting>> = useCallback(
    async (data) => {
      if (packageId) {
        try {
          setIsSaving(true);
          const res = await store.save(packageId, data as IShooting);
          reset(res);
          message.success('Данные сохранены. Вы можете вернуться к заполнению рабочего листа позже');
        } finally {
          setIsSaving(false);
        }
      }
    },
    [packageId]
  );

  const onClickFinalize = useCallback(async () => {
    if (packageId) {
      try {
        setIsFinishing(true);
        const isConfirmed = await confirm({
          title: 'Подтверждение выполнения',
          content: (
            <pre>
              Вы уверены, что хотите подтвердить выполнение работы?
              <br />
              После этого редактирование блока "Съемка" будет недоступно
            </pre>
          ),
        });

        if (!isConfirmed) {
          return;
        }

        const completedResearch = await store.completeResearch(packageId);
        reset(completedResearch);
        // Для того чтобы обновить информацию (статус) в левом зелленом блоке
        packageStore.fetch(packageId, PurposeType.ForEngravingShooting);
        message.success('Выполнение работы подтверждено!');
      } finally {
        setIsFinishing(false);
      }
    }
  }, [packageId, reset]);

  return (
    <Form
      className={'worksheet__diagnostic'}
      metadata={metadata}
      control={control}
      errors={errors}
      disabled={!!isWorkCompleted}
      isLoading={isLoading}
    >
      <FormButtons>
        <CloseButton to={`../../${PurposeType.ForEngravingShooting}`} />
        {!isWorkCompleted ? (
          <>
            <Button.Contained onClick={handleSubmit(onSubmit)} disabled={!isDirty} isLoading={isSaving}>
              Сохранить
            </Button.Contained>
            <Button.Outlined onClick={onClickFinalize} disabled={!store.data} isLoading={isFinishing}>
              Подтвердить выполнение
            </Button.Outlined>
          </>
        ) : null}
        {fullScreenFile ? <ImageViewerFullScreen fileName={fullScreenFile} onHide={onHide} /> : null}
      </FormButtons>
    </Form>
  );
});
