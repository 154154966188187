import { makeObservable, observable, runInAction } from 'mobx';

import { Services } from '../services';
import { IDiagnostic, IPackage } from '../view-models';

import { FilesStore } from './files-store';

const DefaultDiagnosticData: IDiagnostic = {
  mineral: null,
  comment: null,
  files: [],
  isWorkCompleted: false,
};

export class DiagnosticStore {
  @observable public data: IDiagnostic | undefined = undefined;

  public constructor(
    private services: Services,
    private filesStore: FilesStore
  ) {
    makeObservable(this);
  }

  public fetch = async (packageId: IPackage['packageId']): Promise<IDiagnostic> => {
    const res = await this.services.diagnostic.get(packageId);
    runInAction(() => {
      this.data = res ? observable(res) : undefined;
      if (this.data) {
        const files = this.data.files;
        if (!files?.length) {
          return;
        }

        Promise.allSettled(
          files.map((fileName) => this.filesStore.fetch(`worksheet/${packageId}/diagnostics-files/${fileName}`, fileName))
        );
      }
    });

    return { ...DefaultDiagnosticData, ...(res ?? {}) };
  };

  public save = async (packageId: IPackage['packageId'], data: IDiagnostic): Promise<IDiagnostic> => {
    const newFiles = data.files
      .map((fileName: string) => this.filesStore.get(fileName))
      .filter((file) => file instanceof File) as File[];

    const res = await this.services.diagnostic.save(packageId, { ...data, newFiles }, this.data);
    runInAction(() => {
      this.data = res ? observable(res) : undefined;
    });

    return res;
  };

  public completeResearch = async (packageId: IPackage['packageId']): Promise<IDiagnostic> => {
    return await this.services.diagnostic.finalize(packageId);
  };
}
