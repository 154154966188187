import dayjs from 'dayjs';

import { IOrderDTO } from '../models';
import { dayjsToISODateString, IApi, IServerResponseDTO } from '../utils';
import { IOrder } from '../view-models';

export class OrdersService {
  public constructor(private api: IApi) {}

  public async get(): Promise<IOrder[]> {
    const res = await this.api.get({
      url: `requests`,
    });

    return res.body?.items.map(this.toViewModel);
  }

  public async getBySearch(search?: string): Promise<IOrder[]> {
    const res = await this.api.get({
      url: `requests/number/${search}`,
    });

    return res.body?.items.map(this.toViewModel);
  }

  public async getById(orderId: string): Promise<IOrder> {
    const res = await this.api.get({
      url: `requests/${orderId}`,
    });

    return this.toViewModel(res.body);
  }

  public async create(newOrder: Partial<IOrder>): Promise<IOrder['id']> {
    const res = await this.api.post({
      url: 'requests/create-request',
      body: {
        number: newOrder.number,
        clientId: newOrder.clientId,
        date: dayjsToISODateString(newOrder.date),
      },
    });

    return res.body?.id;
  }

  public async update(order: Partial<IOrder>) {
    await this.api.put({
      url: 'requests/update-request',
      body: {
        id: order.id,
        number: order.number,
        clientId: order.clientId,
        date: dayjsToISODateString(order.date),
      },
    });
  }

  private toViewModel(order: IOrderDTO): IOrder {
    return {
      ...order,
      date: order.date ? dayjs(order.date) : undefined,
      clientType: order.client.clientType,
      clientId: order.client.id,
    };
  }

  public async getDocuments(order: IOrder): Promise<Array<{ filename?: string; file?: Blob }>> {
    const res = (
      await Promise.allSettled(
        ['download/acceptance-statements/for-legal-client', 'download/requests/for-legal-client'].map((url) =>
          this.api.post({
            url,
            body: {
              date: dayjsToISODateString(order.date),
              number: order.number,
              client: order.client,
              packages: order.packages,
            },
          })
        )
      )
    )
      ?.filter(({ status }: PromiseSettledResult<IServerResponseDTO>) => status === 'fulfilled')
      .map((item: any) => (item as PromiseFulfilledResult<IServerResponseDTO>).value);

    return res.map((item) => {
      return {
        filename: item.headers.get('Content-Disposition')?.match(/filename=(.*);/)?.[1],
        file: item?.body,
      };
    });
  }

  public async complete(orderId: IOrder['id']): Promise<IOrder> {
    const res = await this.api.put({
      url: `requests/finalize/${orderId}`,
    });

    return this.toViewModel(res.body);
  }
}
