import { ChangeEventHandler, FC } from 'react';
import { styled } from '@mui/material/styles';

import { IBaseButtonProps } from './base-button';
import { OutlinedButton } from './outlined-button';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export interface IUploadButtonProps extends Omit<IBaseButtonProps, 'onChange'> {
  multiple?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const UploadButton: FC<IUploadButtonProps> = (props) => {
  const { children, onChange, multiple, ...restProps } = props;

  return (
    <OutlinedButton component="label" role={undefined} tabIndex={-1} {...restProps}>
      {children}
      <VisuallyHiddenInput type="file" onChange={onChange} multiple={multiple} />
    </OutlinedButton>
  );
};
