import { FC, useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { MobXProviderContext, observer } from 'mobx-react';

import { FormWrapper, PageWrapper, Panel, Table, UnderlinedHeader } from '../../components';
import { PurposeType, WorkItems } from '../../constants';
import { Stores } from '../../stores';
import { IPackage } from '../../view-models';
import { WhiteGreyTableTheme } from '../common';
import { metadata } from '../orders-list/packages/metadata';

import './packages-list.css';

interface IPackagesListProps {
  purposeType: PurposeType;
}

export const PackagesList: FC<IPackagesListProps> = observer((props) => {
  const { packagesListStore: store } = useContext(MobXProviderContext) as Stores;
  const { purposeType } = props;

  const navigate = useNavigate();

  useEffect(() => {
    store.init(purposeType);

    return store.finalize;
  }, []);

  const onDoubleClick = useCallback((pack: IPackage) => {
    switch (purposeType) {
      case PurposeType.ForExpertise: {
        navigate(`/packages/${pack.packageId}/worksheet/${WorkItems.Diagnostics}`);
        break;
      }
      case PurposeType.ForEngravingShooting: {
        navigate(`/packages/${pack.packageId}/worksheet/${WorkItems.Engraving}`);
        break;
      }
      default: {
        navigate(`/orders/${pack?.requestId}/packages/${pack.packageId}`);
      }
    }
  }, []);

  return (
    <PageWrapper>
      <UnderlinedHeader>Пакеты</UnderlinedHeader>
      <FormWrapper>
        {store.data?.length === 0 && <span>Нет ни одного пакета</span>}
        {store.data?.length ? (
          <Panel className="packages-list__panel">
            <ThemeProvider theme={WhiteGreyTableTheme}>
              <Table metadata={metadata} data={store.data} keyField="packageId" onDoubleClickRow={onDoubleClick} />
            </ThemeProvider>
          </Panel>
        ) : null}
      </FormWrapper>
    </PageWrapper>
  );
});
