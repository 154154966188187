import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button, Form, FormButtons, FormFieldType, IMetadataField } from '../../../../components';
import { CloseButton } from '../../../../components/buttons/close-button';
import { PurposeType } from '../../../../constants';
import { Stores } from '../../../../stores';
import { message, useConfirm } from '../../../../utils';
import { IDiagnostic } from '../../../../view-models';

import { DiagnosticFiles } from './diagnostic-files';

export const Diagnostic: FC = observer(() => {
  const { diagnosticStore: store, dictionaryStore, packageStore } = useContext(MobXProviderContext) as Stores;
  const { confirm } = useConfirm();
  const { packageId } = useParams();

  const [isFinishing, setIsFinishing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<Partial<IDiagnostic>>({
    values: {},
  });

  const mineral = watch('mineral');
  const isWorkCompleted = watch('isWorkCompleted');

  const loadFormData = useCallback(
    async (packageId?: string) => {
      if (packageId) {
        try {
          setIsLoading(true);
          const data = await store.fetch(packageId);
          reset(data);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [reset, packageId, setIsLoading]
  );

  useEffect(() => {
    loadFormData(packageId);

    return reset;
  }, [loadFormData, packageId, reset]);

  const onChangeFiles = useCallback(
    (files?: string[]) => {
      setValue('files', files, { shouldDirty: true });
    },
    [setValue]
  );

  const metadata: IMetadataField[] = useMemo(
    () => [
      {
        name: 'mineral',
        label: 'Вид минерала',
        type: FormFieldType.SELECT,
        url: 'consul/minerals',
        rules: { required: 'Обязательно к заполнению' },
      },
      {
        name: 'comment',
        label: 'Комментарий',
        type: FormFieldType.TEXT,
      },
      ...(mineral === 'Brilliant'
        ? ([
            {
              name: 'files',
              label: 'Aurora Diamond',
              type: FormFieldType.CUSTOM,
              template: (props) => <DiagnosticFiles value={props.value} onChange={onChangeFiles} disabled={isWorkCompleted} />,
            },
          ] as IMetadataField[])
        : []),
    ],
    [onChangeFiles, isWorkCompleted, mineral]
  );

  const onSubmit: SubmitHandler<Partial<IDiagnostic>> = useCallback(
    async (data) => {
      if (packageId) {
        try {
          setIsSaving(true);
          const res = await store.save(packageId, data as IDiagnostic);
          reset(res);
          message.success('Данные сохранены. Вы можете вернуться к заполнению рабочего листа позже');
        } finally {
          setIsSaving(false);
        }
      }
    },
    [store, packageId, setIsSaving]
  );

  const onClickFinalize = useCallback(async () => {
    if (packageId) {
      try {
        setIsFinishing(true);
        const isConfirmed = await confirm({
          title: 'Завершение исследования',
          content: (
            <pre>
              Вы уверены, что хотите завершить исследование?
              <br />
              После этого редактирование блока "Диагностика" будет недоступно
            </pre>
          ),
        });

        if (!isConfirmed) {
          return;
        }

        const completedResearch = await store.completeResearch(packageId);
        reset(completedResearch);
        // Для того чтобы обновить информацию (статус) в левом зелленом блоке
        packageStore.fetch(packageId, PurposeType.ForExpertise);
        message.success('Исследование завершено!');
      } finally {
        setIsFinishing(false);
      }
    }
  }, [packageId, store, reset]);

  return (
    <Form
      className={'worksheet__diagnostic'}
      metadata={metadata}
      control={control}
      store={dictionaryStore}
      errors={errors}
      disabled={isWorkCompleted}
      isLoading={isLoading}
    >
      <FormButtons>
        <CloseButton />
        {!isWorkCompleted ? (
          <>
            <Button.Contained onClick={handleSubmit(onSubmit)} disabled={!isDirty} isLoading={isSaving}>
              Сохранить
            </Button.Contained>
            <Button.Outlined onClick={onClickFinalize} disabled={!store.data} isLoading={isFinishing}>
              Завершить исследование
            </Button.Outlined>
          </>
        ) : null}
      </FormButtons>
    </Form>
  );
});
