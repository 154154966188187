import { outlinedInputClasses } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ruRU } from '@mui/x-date-pickers/locales';

export const theme = createTheme(
  {
    palette: {
      primary: { main: '#206a44' },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            padding: `14px 30px`,
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.05em',

            ...(ownerState.variant === 'outlined' && {
              backgroundColor: '#ffffff',
              borderColor: '#bababa',
              color: '#333333',

              [`&:hover`]: {
                backgroundColor: '#fafafa',
              },
            }),
          }),
        },
      },
      MuiChip: {
        styleOverrides: {
          root: () => ({
            borderRadius: '20px',
            height: '43px',
            fontSize: '16px',
          }),
          label: () => ({
            paddingLeft: '20px',
            paddingRight: '20px',
          }),
        },
      },
      MuiStack: {
        styleOverrides: {
          root: () => ({
            flexWrap: 'wrap',
            gap: '20px',
          }),
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: () => ({
            borderRadius: '10px',
            borderColor: '#cccccc',
            color: '#333333',

            [`&:disabled, &.Mui-disabled, .${outlinedInputClasses.disabled}`]: {
              backgroundColor: '#fafafa',
              color: '#777777',
              borderColor: '#fafafa',
              borderRadius: '10px',
            },

            [`.${outlinedInputClasses.disabled} ~ .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: '#fafafa',
            },
          }),
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: () => ({
            color: '#777777',
          }),
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            height: '40px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            borderBottom: 'none',
            color: 'inherit',
            fontSize: '14px',
            fontWeight: '400',
            letterSpacing: '0.05em',
            lineHeight: '19px',
            padding: '10px',
          },
          head: {
            backgroundColor: '#ffffff',
            color: '#777777',
            fontSize: '12px',
            letterSpacing: '0.1em',
            lineHeight: '15px',
            whiteSpace: 'nowrap',
          },
        },
      },
    },
  },
  ruRU // x-date-pickers translations
);
