import { ChangeEvent, FC, useCallback, useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import lodash from 'lodash';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button } from '../../../../components';
import { IUploadButtonProps } from '../../../../components/buttons/upload-button';
import { Stores } from '../../../../stores';
import { message } from '../../../../utils';

interface IAddImageButton extends Omit<IUploadButtonProps, 'onChange' | 'startIcon'> {
  value?: string[];
  onChange?: (fileName: string[]) => void;
}

export const AddImageButton: FC<IAddImageButton> = observer((props) => {
  const { onChange, value = [], ...restProps } = props;
  const { filesStore: store } = useContext(MobXProviderContext) as Stores;

  const onAddFiles = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const filesBlob = Array.from(event.target.files ?? []);
      const newFiles: string[] = filesBlob.map((file: File) => file.name);

      const intersection = lodash.intersection(value, newFiles);

      if (intersection.length > 0) {
        message.error(
          `Не добавлено так как файлы с именами ${intersection.join(', ')} уже добавлены.\nЕсли это другие файлы, пожалуйста, перед добавлением переименуте их.`
        );
      } else {
        filesBlob.forEach((file: File) => {
          store.set(file.name, file);
        });

        onChange?.([...(value ?? []), ...newFiles]);
      }
      event.target.value = '';
    },
    [onChange, value]
  );

  return (
    <Button.Upload {...restProps} startIcon={<AddIcon />} onChange={onAddFiles}>
      Изображение
    </Button.Upload>
  );
});
