import { FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { ImageViewer } from '../../../../components';
import { useConfirm } from '../../../../utils';
import { AddImageButton } from '../common/add-image-button';

interface IAddDiagnosticFilesProps {
  value?: string[];
  onChange: (file?: string[]) => void;
  disabled?: boolean;
}

export const DiagnosticFiles: FC<IAddDiagnosticFilesProps> = observer((props) => {
  const { disabled, onChange, value } = props;
  const { confirm } = useConfirm();

  const onDelete = useCallback(
    async (file: string) => {
      const isConfirmed = await confirm({
        title: 'Удаление файла',
        content: `Подтвердите удаление файла ${file}`,
      });

      if (isConfirmed) {
        onChange(value?.filter((f) => f !== file));
      }
    },
    [onChange, value]
  );

  return (
    <div className={'diagnostic-files'}>
      {value?.length ? (
        <div className={'diagnostic-files__grid'}>
          {value?.map((file: string) => (
            <ImageViewer key={file} fileName={file} onDelete={!disabled ? () => onDelete(file) : undefined} />
          ))}
        </div>
      ) : null}
      {!disabled ? <AddImageButton multiple onChange={onChange} value={value} disabled={(value?.length ?? 0) >= 5} /> : null}
    </div>
  );
});
