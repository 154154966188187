import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { MobXProviderContext, observer } from 'mobx-react';

import { FormWrapper, InfoBlock, PageWrapper, Panel, Tabs, UnderlinedHeader } from '../../components';
import { PackageState, packageStateMap, PurposeType, WorkItems } from '../../constants';
import { Stores } from '../../stores';
import { getPurposeTypeByWorkItem } from '../../utils';
import { IDictionaryItem, IPackage } from '../../view-models';

import { Diagnostic, Engraving, Gemology, Measurement, Plotting, Shooting } from './tabs';

import './worksheet.css';

export const Worksheet: FC = observer(() => {
  const { packageStore: store } = useContext(MobXProviderContext) as Stores;
  const { packageId, tab } = useParams();
  const navigate = useNavigate();

  const purposeType = useMemo(() => getPurposeTypeByWorkItem(tab as WorkItems), [tab]);

  useEffect(() => {
    if (packageId) {
      store.fetch(packageId, purposeType as PurposeType);
    }
  }, [store, packageId, purposeType]);

  const statusInfo = useMemo(
    () => [
      {
        label: 'Статус',
        value: `${packageStateMap.get(store.data?.state as PackageState) ?? '-'}`,
      },
      {
        label: 'Дата передачи в работу',
        value: (store.data?.worksheet?.startDate && dayjs(store.data?.worksheet?.startDate).format('DD.MM.YYYY')) || '-',
      },
      {
        label: 'Количество, шт',
        value: `${store.data?.quantityFact ?? '-'}`,
      },
      {
        label: 'Вес, ct',
        value: `${store.data?.weightFact ?? '-'}`,
      },
      ...(process.env.NODE_ENV === 'development'
        ? [
            {
              label: 'Система оценки',
              value: `${Array.from(store.data?.certificationSystem ?? [])?.join(', ')}` || '-',
            },
          ]
        : []),
    ],
    [store.data]
  );

  const tabs: Array<{
    id: string;
    name: string;
    isHide: (type: PurposeType, pack: IPackage | null) => boolean;
    component: FC;
  }> = useMemo(() => {
    return [
      {
        id: WorkItems.Diagnostics,
        name: 'Диагностика',
        isHide: (type: PurposeType) => type !== PurposeType.ForExpertise,
        component: Diagnostic,
      },
      {
        id: WorkItems.Measurement,
        name: 'Параметры',
        isHide: (type: PurposeType) => type !== PurposeType.ForExpertise,
        component: Measurement,
      },
      {
        id: WorkItems.Gemology,
        name: `Геммологическая\nэкспертиза`,
        isHide: (type: PurposeType) => type !== PurposeType.ForExpertise,
        component: Gemology,
      },
      {
        id: WorkItems.Plotting,
        name: 'Плоттинг',
        isHide: (type: PurposeType, pack: IPackage | null) =>
          type !== PurposeType.ForExpertise || !pack?.additionalWorkScope?.has('Plotting'),
        component: Plotting,
      },
      {
        id: WorkItems.Engraving,
        name: 'Гравировка',
        isHide: (type: PurposeType, pack: IPackage | null) => {
          return (
            type !== PurposeType.ForEngravingShooting ||
            !(pack?.additionalWorkScope?.has('StandardEngraving') || pack?.additionalWorkScope?.has('CustomEngraving'))
          );
        },
        component: Engraving,
      },
      {
        id: WorkItems.Shooting,
        name: 'Съемка',
        isHide: (type: PurposeType, pack: IPackage | null) => {
          return type !== PurposeType.ForEngravingShooting || !pack?.additionalWorkScope?.has('Shooting');
        },
        component: Shooting,
      },
    ].filter((tab) => !tab.isHide(purposeType as PurposeType, store.data));
  }, [purposeType, store.data]);

  const placholder = useMemo(() => {
    switch (purposeType) {
      case PurposeType.ForEngravingShooting:
        return 'Для данного пакета в разделе дополнительных работ не указаны ни гравировка ни съемка';
      default:
        return 'Нет доступных разделов';
    }
  }, [purposeType]);

  const Component: FC | undefined = tabs.find(({ id }) => id === tab)?.component;

  const onSelectTab = useCallback(
    (tab: IDictionaryItem) => {
      navigate(`../${tab.id}`);
    },
    [navigate]
  );

  useEffect(() => {
    if (store.data && !Component && tabs?.[0]) {
      navigate(`../${tabs?.[0]?.id}`);
    }
  }, [Component, navigate, tabs, store.data]);

  return (
    <PageWrapper>
      <UnderlinedHeader>Рабочий лист пакета №{store.data?.number}</UnderlinedHeader>
      <FormWrapper>
        <InfoBlock data={statusInfo} />
        <Panel className="worksheet__panel">
          {!Component && store.data ? <div className="worksheet__placeholder">{placholder}</div> : null}

          {tabs?.length > 0 && (
            <>
              <Tabs data={tabs} selectedId={tab} onSelect={onSelectTab} />
              {Component && (
                <div className="worksheet__component-wrapper">
                  <Component />
                </div>
              )}
            </>
          )}
        </Panel>
      </FormWrapper>
    </PageWrapper>
  );
});
