import { FormFieldType, IMetadataField } from '../../components';
import { minValidator } from '../../utils';
import { IDictionaryItem } from '../../view-models';

export const getMultipleStonesMetadata = (): Array<IMetadataField> => {
  const isCertificationDocumentTypeDisabled = (item: IDictionaryItem) => {
    return !['SmallCertificateOfConformity'].includes(item?.id as string);
  };

  const isContainerDisabled = (item: IDictionaryItem) => {
    return !['PaperBag'].includes(item?.id as string);
  };

  return [
    {
      name: 'cut',
      label: 'Вид огранки',
      type: FormFieldType.SELECT,
      url: 'consul/cut',
      rules: { required: 'Обязательно к заполнению' },
    },
    {
      name: 'weightFact',
      label: 'Вес фактический, ct',
      type: FormFieldType.FLOAT,
      rules: { required: 'Обязательно к заполнению' },
    },
    { name: 'weightPlan', label: 'Вес заявленный, ct', description: '(при расхождении)', type: FormFieldType.FLOAT },
    {
      name: 'quantityFact',
      label: 'Кол-во фактическое',
      type: FormFieldType.INTEGER,
      rules: {
        required: 'Обязательно к заполнению',
        validate: {
          min: minValidator(1),
        },
      },
    },
    {
      name: 'quantityPlan',
      label: 'Кол-во заявленное',
      description: '(при расхождении)',
      type: FormFieldType.INTEGER,
      rules: {
        validate: {
          min: minValidator(1),
        },
      },
    },
    {
      name: 'certificationSystem',
      label: 'Система оценки',
      type: FormFieldType.CHECKBOX_GROUP,
      url: 'consul/certification-system',
      rules: { required: 'Обязательно к заполнению' },
    },
    {
      name: 'certificationDocumentType',
      label: 'Вид сертификационного документа',
      type: FormFieldType.RADIO,
      url: 'consul/certification-document-type',
      rules: { required: 'Обязательно к заполнению' },
      isFieldHide: isCertificationDocumentTypeDisabled,
    },
    {
      name: 'container',
      label: 'Упаковка',
      type: FormFieldType.RADIO,
      url: 'consul/container',
      rules: { required: 'Обязательно к заполнению' },
      isFieldHide: isContainerDisabled,
    },
  ];
};
