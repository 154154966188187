import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import { computed, makeObservable, observable, runInAction } from 'mobx';

import { parseToken } from '../utils';

dayjs.extend(durationPlugin);

export class JwtToken {
  @observable private isTokenExpired = false;

  public constructor(public readonly tokenString: string) {
    makeObservable(this);
    const parsedToken = parseToken(tokenString);

    const exp = dayjs(1000 * (parsedToken?.exp ?? 0));
    const now = dayjs();

    const duration = dayjs.duration(exp.diff(now)).asMilliseconds();

    if (duration > 0) {
      window.setTimeout(
        () => {
          runInAction(() => {
            this.isTokenExpired = true;
          });
        },
        duration - 30 * 1000
      );
    } else {
      this.isTokenExpired = true;
    }
  }

  @computed
  public get isExpired(): boolean {
    return this.isTokenExpired;
  }
}
