import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import { OutlinedInputProps } from '@mui/material/OutlinedInput/OutlinedInput';
import { observer } from 'mobx-react';

export const FieldText: ForwardRefExoticComponent<Omit<OutlinedInputProps, 'ref'> & RefAttributes<unknown>> = observer(
  forwardRef(({ value, ...props }, ref) => {
    return <OutlinedInput {...props} ref={ref} value={value ?? ''} />;
  })
);
