import { FC } from 'react';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material/Button/Button';
import { styled } from '@mui/material/styles';
import cn from 'classnames';

import './base-button.css';

const RoundedButton = styled(Button)({
  textTransform: 'none',
  borderRadius: '25px',
});

export interface IBaseButtonProps extends ButtonProps {
  isLoading?: boolean;
}

export const BaseButton: FC<IBaseButtonProps> = (props) => {
  const { isLoading, ...restProps } = props;

  if (isLoading) {
    restProps.startIcon = <RotateRightIcon className="base-button__load-icon" />;
    restProps.disabled = true;
  }

  return <RoundedButton className={cn('base-button', isLoading && 'base-button__loading')} {...restProps} />;
};
