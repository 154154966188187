import { FC, useCallback, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { observer } from 'mobx-react';
import { QRCodeSVG } from 'qrcode.react';

import './qr-code-button.css';

interface IQrCodeButtonProps {
  data: string;
}

export const QrCodeButton: FC<IQrCodeButtonProps> = observer((props) => {
  const { data } = props;

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef, bodyClass: 'qr-code-button__print-area' });

  const clickQR = useCallback(() => {
    reactToPrintFn();
  }, [reactToPrintFn]);

  return (
    <>
      <QrCodeIcon onClick={clickQR} className={'qr-code-button'} />
      <div ref={contentRef} className={'qr-code-button__print-area'}>
        <QRCodeSVG value={data} size={64} includeMargin marginSize={2} />
        <span>{data}</span>
      </div>
    </>
  );
});
