import { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import lodash from 'lodash';
import { reaction } from 'mobx';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button, Form, FormButtons, FormFieldType, IMetadataField } from '../../../../components';
import { CloseButton } from '../../../../components/buttons/close-button';
import { PackageType, PurposeType } from '../../../../constants';
import { Stores } from '../../../../stores';
import { message, useConfirm } from '../../../../utils';
import { IGemology, IPackage } from '../../../../view-models';

import { getMultipleStonesMetadata } from './get-multiple-stones-metadata';
import { getSingleStoneMetadata } from './get-single-stone-metadata';
import { theme } from './theme';

export const Gemology: FC = observer(() => {
  const { gemologyStore: store, dictionaryStore, packageStore } = useContext(MobXProviderContext) as Stores;
  const { confirm } = useConfirm();
  const { packageId } = useParams();

  const [isFinishing, setIsFinishing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const {
    clearErrors,
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
  } = useForm<Partial<IGemology>>({
    values: {
      defects: new Set([]),
    },
  });

  const isWorkCompleted = watch('isWorkCompleted');
  const isFantasyShape = watch('isFantasyShape');
  const isFantasyColor = watch('isFantasyColor');

  const pack: IPackage | null = useMemo(() => packageStore.data, [packageStore.data]);

  const isSingleStone: boolean = useMemo(() => pack?.type === PackageType.SingleStone ?? false, [pack]);
  const isGia: boolean = useMemo(() => pack?.certificationSystem?.has('Worldwide') || false, [pack]);
  const isRussian: boolean = useMemo(() => pack?.certificationSystem?.has('Russian') || false, [pack]);

  const loadFormData = useCallback(
    async (packageId?: string) => {
      if (packageId) {
        try {
          setIsLoading(true);
          const data: IGemology = await store.fetch(packageId);
          reset(data);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [store, packageId, setIsLoading]
  );

  useEffect(() => {
    loadFormData(packageId);
  }, [loadFormData, packageId]);

  useEffect(() => {
    const errorObserver = reaction(
      () => store.errors,
      (errors) => {
        if (errors) {
          Object.entries(errors).forEach(([key, value]) => {
            setError(key as keyof IGemology, {
              type: 'custom',
              message: value,
            });
          });
        }
      },
      {
        fireImmediately: true, // чтобы reaction срабатывал и в первый раз
        equals: (prev?: Record<keyof IGemology, string>, next?: Record<keyof IGemology, string>): boolean =>
          lodash.isEqual(prev, next),
        delay: 500,
      }
    );

    return () => {
      errorObserver?.();
      store.finalize();
    };
  }, [store, reset, setError, clearErrors]);

  const changeIsFantasyShapeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue('isFantasyShape', event.target.checked, { shouldDirty: true });
      setValue('shapeRu', null, { shouldDirty: true });
      setValue('fantasyCutShapeRu', null, { shouldDirty: true });
    },
    [setValue]
  );

  const changeIsFantasyColorHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue('isFantasyColor', event.target.checked, { shouldDirty: true });
      setValue('fantasyColorGia', null, { shouldDirty: true });

      setValue('ordinalColorGia', null, { shouldDirty: true });
    },
    [setValue]
  );

  const metadata: IMetadataField[] = useMemo(
    () =>
      [
        'devider',
        {
          name: 'origin',
          label: 'Натуральность происхождения',
          type: FormFieldType.SELECT,
          url: 'consul/minerals-origin',
          rules: { required: 'Обязательно к заполнению' },
        },
        {
          name: 'isFantasyShape',
          label: 'Фантазийная форма',
          type: FormFieldType.CHECKBOX,
          onChange: changeIsFantasyShapeHandler,
        },
        ...(isRussian
          ? [
              'devider',
              'Форма огранки РФ',
              {
                name: 'shapeRu',
                label: 'Наименование',
                type: FormFieldType.SELECT,
                url: `consul/diamond-shape-ru/isFantasyShape=${!!isFantasyShape}`,
                rules: { required: isRussian && 'Обязательно к заполнению' },
                disabled: !isRussian,
              },
              {
                name: 'fantasyCutShapeRu',
                label: 'Форма огранки',
                type: FormFieldType.SELECT,
                url: 'consul/diamond-fantasy-cut-shape-ru',
                disabled: !isFantasyShape,
              },
            ]
          : []),
        ...(isGia
          ? [
              'devider',
              'Форма огранки GIA',
              {
                name: 'shapeGia',
                label: 'Наименование',
                type: FormFieldType.SELECT,
                url: 'consul/diamond-shape-gia',
                rules: { required: isGia && 'Обязательно к заполнению' },
                disabled: !isGia,
              },
              {
                name: 'cutStyle',
                label: 'Стиль огранки',
                type: FormFieldType.SELECT,
                url: 'consul/diamond-cut-style-gia',
              },
            ]
          : []),
        'devider',
        ...(isSingleStone
          ? getSingleStoneMetadata(isGia, isRussian, !!isFantasyColor, changeIsFantasyColorHandler)
          : getMultipleStonesMetadata(isGia, isRussian, !!isFantasyColor, changeIsFantasyColorHandler)),
        'devider',
        {
          name: 'comment',
          label: 'Комментарий',
          type: FormFieldType.TEXT,
          className: 'comment',
        },
      ].map((item: IMetadataField | string, index: number) => {
        if (item === 'devider') {
          return {
            id: `devider${index}`,
            name: 'devider',
            type: FormFieldType.CUSTOM,
            className: 'fullWith',
            template: () => <div className={'line'} />,
          };
        } else if (typeof item === 'string') {
          return {
            id: `header${index}`,
            name: `header`,
            type: FormFieldType.CUSTOM,
            className: 'fullWith',
            template: () => <div className={'block-header'}>{item}</div>,
          };
        }

        return item;
      }),
    [isFantasyShape, isSingleStone, isRussian, isGia, isFantasyColor, changeIsFantasyShapeHandler, changeIsFantasyColorHandler]
  );

  const onSubmit: SubmitHandler<Partial<IGemology>> = useCallback(
    async (data) => {
      if (packageStore.data) {
        try {
          setIsSaving(true);
          const res = await store.save(packageStore.data, data as IGemology);
          reset(res);
          message.success('Данные сохранены. Вы можете вернуться к заполнению рабочего листа позже');
        } finally {
          setIsSaving(false);
        }
      }
    },
    [store, packageStore.data, reset]
  );

  const onClickFinalize = useCallback(async () => {
    if (packageId) {
      try {
        setIsFinishing(true);
        const isConfirmed = await confirm({
          title: 'Завершение исследования',
          content: (
            <pre>
              Вы уверены, что хотите завершить исследование?
              <br />
              После этого редактирование блока "Геммологическая экспертиза" будет недоступно
            </pre>
          ),
        });

        if (!isConfirmed) {
          return;
        }

        const completedResearch = await store.completeResearch(packageId);
        reset(completedResearch);
        // Для того чтобы обновить информацию (статус) в левом зелленом блоке
        packageStore.fetch(packageId, PurposeType.ForExpertise);
        message.success('Исследование завершено!');
      } finally {
        setIsFinishing(false);
      }
    }
  }, [packageId, store, reset]);

  return (
    <ThemeProvider theme={theme}>
      <Form
        className={'worksheet__gemology'}
        metadata={metadata}
        control={control}
        store={dictionaryStore}
        errors={errors}
        disabled={isWorkCompleted}
        isLoading={isLoading}
      >
        <FormButtons>
          <CloseButton />
          {!isWorkCompleted && (
            <>
              <Button.Contained
                onClick={handleSubmit(onSubmit)}
                disabled={!Object.keys(dirtyFields).length}
                isLoading={isSaving}
              >
                Сохранить
              </Button.Contained>
              <Button.Outlined disabled={!store.data} onClick={onClickFinalize} isLoading={isFinishing}>
                Завершить исследование
              </Button.Outlined>
            </>
          )}
        </FormButtons>
      </Form>
    </ThemeProvider>
  );
});
