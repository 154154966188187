import { makeObservable, observable, runInAction } from 'mobx';

import { Services } from '../services';
import { IEngraving, IPackage } from '../view-models';

export class EngravingStore {
  @observable public data: IEngraving | undefined = undefined;

  public constructor(private services: Services) {
    makeObservable(this);
  }

  public fetch = async (packageId: IPackage['packageId']): Promise<IEngraving> => {
    const res = await this.services.engraving.get(packageId);
    runInAction(() => {
      this.data = res ? observable(res) : undefined;
    });

    return res;
  };

  public completeResearch = async (packageId: IPackage['packageId']): Promise<IEngraving> => {
    return await this.services.engraving.finalize(packageId);
  };
}
