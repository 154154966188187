import { FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { ImageViewer } from '../../../../components';
import { IPlotting } from '../../../../view-models';
import { AddImageButton } from '../common/add-image-button';

interface IAddPlottingFileProps {
  value?: IPlotting['fileIdentity'];
  onChange: (file?: string) => void;
  disabled?: boolean;
}

export const PlottingFile: FC<IAddPlottingFileProps> = observer((props) => {
  const { onChange, value, disabled } = props;

  const AddFile = useCallback(
    (files: string[]) => {
      onChange(files[0]);
    },
    [onChange]
  );

  const onDelete = useCallback(() => {
    onChange();
  }, [onChange]);

  return (
    <div className="diagnostic-files">
      {value ? <ImageViewer fileName={value} onDelete={!disabled ? onDelete : undefined} /> : null}
      {!disabled ? <AddImageButton onChange={AddFile} /> : null}
    </div>
  );
});
