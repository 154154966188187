export enum FieldType {
  CHECKBOX = 'checkbox',
  CHECKBOX_GROUP = 'checkbox-group',
  CUSTOM = 'custom',
  DATE = 'date',
  FLOAT = 'float',
  INTEGER = 'integer',
  PASSWORD = 'password',
  RADIO = 'radio',
  SELECT = 'select',
  TEXT = 'text',
}
