import { FC, useCallback, useContext, useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import cn from 'classnames';
import { MobXProviderContext, observer } from 'mobx-react';

import { Stores } from '../../stores';

import { ImageViewerFullScreen } from './image-viewer-full-screen';

import './image-viewer.css';

export const ImageViewer: FC<{
  className?: string;
  fileName: string;
  onDelete?: () => void;
}> = observer((props) => {
  const { filesStore: store } = useContext(MobXProviderContext) as Stores;
  const { className, fileName, onDelete } = props;
  const file = store.files.get(fileName);
  const isLoading = store.inLoading.has(fileName);

  const [isFullMode, setFullMode] = useState(false);

  const onClick = useCallback(() => {
    setFullMode(true);
  }, [setFullMode]);

  const onHide = useCallback(() => {
    setFullMode(false);
  }, [setFullMode]);

  return (
    <div className={cn('image-viewer', className)} title={fileName}>
      <div className={'image-viewer__image-container'}>
        {file && (
          <img
            className={'image-viewer__image'}
            id={`img-${fileName}`}
            alt={fileName}
            src={URL.createObjectURL(file)}
            onClick={onClick}
          />
        )}
        {!file && isLoading && <RotateRightIcon className="image-viewer__load-icon" />}
      </div>
      {onDelete && <HighlightOffIcon onClick={onDelete} className={'image-viewer__close-icon'} />}
      <span className="image-viewer__fileName">{fileName}</span>

      {isFullMode ? <ImageViewerFullScreen fileName={fileName} onHide={onHide} /> : null}
    </div>
  );
});
