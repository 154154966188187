import { action, makeObservable, observable, ObservableMap } from 'mobx';

import { Services } from '../services';

export class FilesStore {
  /**
   * Общее хранилище файлов, чтобы ускорить процесс работы с ними
   * Если файл уже имеется в наличии выдавать его.
   * Если нет то подгружать в фоне будет.
   */
  @observable public files: ObservableMap<string, File | Blob> = observable.map();
  @observable public inLoading = new Set<string>();

  public constructor(private services: Services) {
    makeObservable(this);
  }

  public async fetch(url: string, fileName: string, force?: boolean): Promise<void> {
    try {
      if (!force && (!url || this.files.has(fileName) || this.inLoading.has(fileName))) {
        return;
      }

      this.inLoading.add(fileName);
      const res = await this.services.files.get(url);
      this.set(fileName, res);
    } catch (error) {
      console.error(error);
      throw new Error(`Не удалось получить файл ${fileName}`);
    } finally {
      this.inLoading.delete(fileName);
    }
  }

  public get(fileName: string): File | Blob | null {
    return this.files.get(fileName) ?? null;
  }

  @action
  public set(fileName: string, file: File | Blob): void {
    this.files.set(fileName, file);
  }

  @action
  public delete(fileName: string): void {
    this.files.delete(fileName);
  }
}
