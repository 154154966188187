import { ChangeEventHandler } from 'react';

import { FormFieldType, IMetadataField } from '../../../../components';

export const getSingleStoneMetadata = (
  isGia: boolean,
  isRussian: boolean,
  isFantasyColor: boolean,
  changeIsFantasyColorHandler: ChangeEventHandler<HTMLInputElement>
): Array<IMetadataField | string> => [
  {
    name: 'cut',
    label: 'Огранка',
    type: FormFieldType.SELECT,
    url: 'consul/diamond-cut-inter-system',
    rules: { required: 'Обязательно к заполнению' },
  },
  {
    name: 'culet',
    label: 'Калетта',
    type: FormFieldType.SELECT,
    url: 'consul/diamond-culet-inter-system',
    rules: { required: 'Обязательно к заполнению' },
  },
  {
    name: 'symmetry',
    label: 'Симметрия',
    type: FormFieldType.SELECT,
    url: 'consul/diamond-symmetry-inter-system',
    rules: { required: 'Обязательно к заполнению' },
  },
  {
    name: 'polish',
    label: 'Полировка',
    type: FormFieldType.SELECT,
    url: 'consul/diamond-polish-inter-system',
    rules: { required: 'Обязательно к заполнению' },
  },
  'devider',
  {
    name: 'isFantasyColor',
    label: 'Фантазийный цвет',
    type: FormFieldType.CHECKBOX,
    onChange: changeIsFantasyColorHandler,
  },
  ...(isFantasyColor
    ? [
        {
          name: 'colorRu',
          label: 'Цвет РФ',
          type: FormFieldType.SELECT,
          url: 'consul/diamond-color-ru',
          rules: { required: isRussian && 'Обязательно к заполнению' },
          disabled: !isRussian,
        },
        ...(isGia
          ? [
              'Цвет GIA',
              {
                name: 'fantasyColorGia.intensity',
                label: 'Интенсивность',
                type: FormFieldType.SELECT,
                url: 'consul/diamond-fantasy-color-gia/intensity',
              },
              {
                name: 'fantasyColorGia.baseColor',
                label: 'Основной',
                type: FormFieldType.SELECT,
                url: 'consul/diamond-fantasy-color-gia/baseColors',
                rules: { required: isGia && 'Обязательно к заполнению' },
              },
              {
                name: 'fantasyColorGia.shade',
                label: 'Оттенок 1',
                type: FormFieldType.SELECT,
                url: 'consul/diamond-fantasy-color-gia/shades',
              },
              {
                name: 'fantasyColorGia.shade2',
                label: 'Оттенок 2',
                type: FormFieldType.SELECT,
                url: 'consul/diamond-fantasy-color-gia/shades',
              },
            ]
          : []),
      ]
    : [
        '',
        {
          name: 'colorRu',
          label: 'Цвет РФ',
          type: FormFieldType.SELECT,
          url: 'consul/diamond-color-ru',
          rules: { required: isRussian && 'Обязательно к заполнению' },
          disabled: !isRussian,
        },
        {
          name: 'ordinalColorGia',
          label: 'Цвет GIA',
          type: FormFieldType.SELECT,
          url: 'consul/diamond-color-gia',
          rules: { required: isGia && 'Обязательно к заполнению' },
          disabled: !isGia,
        },
      ]),
  'devider',
  {
    name: 'clarityRu',
    label: 'Чистота РФ',
    type: FormFieldType.SELECT,
    url: 'consul/diamond-clarity-ru',
    rules: { required: isRussian && 'Обязательно к заполнению' },
    disabled: !isRussian,
  },
  {
    name: 'clarityGia',
    label: 'Чистота GIA',
    type: FormFieldType.SELECT,
    url: 'consul/diamond-clarity-gia',
    rules: { required: isGia && 'Обязательно к заполнению' },
    disabled: !isGia,
  },
  {
    name: 'fluorescence',
    label: 'Флюоресценция',
    type: FormFieldType.SELECT,
    url: 'consul/diamond-fluorescence-inter-system',
    rules: { required: 'Обязательно к заполнению' },
  },
  {
    name: 'externalInfluence',
    label: 'Признаки внешнего воздействия',
    type: FormFieldType.CHECKBOX,
  },
  'devider',
  'Дефекты внешние',
  {
    id: 'outerDefects',
    name: 'defects',
    type: FormFieldType.CHECKBOX_GROUP,
    className: 'fullWith deffects',
    url: 'consul/diamond-defects/outer',
  },
  'devider',
  'Дефекты внутренние',
  {
    id: 'innterDefects',
    name: 'defects',
    type: FormFieldType.CHECKBOX_GROUP,
    className: 'fullWith deffects',
    url: 'consul/diamond-defects/inner',
  },
];
