import { action, makeObservable, observable, runInAction } from 'mobx';

import { Services } from '../services';
import { IGemology, IPackage } from '../view-models';

export class GemologyStore {
  @observable public data: IGemology | undefined = undefined;
  @observable public errors: Record<keyof IGemology, string> | undefined = undefined;

  public constructor(private services: Services) {
    makeObservable(this);
  }

  public fetch = async (packageId: IPackage['packageId']): Promise<IGemology> => {
    const res = await this.services.gemology.get(packageId);
    runInAction(() => {
      this.data = res ? observable(res) : undefined;
    });

    return this.toViewModel(res);
  };

  public save = async (pack: IPackage, data: IGemology): Promise<IGemology> => {
    const res = await this.services.gemology.save(pack, data);

    runInAction(() => {
      this.data = res ? observable(res) : undefined;
    });

    return this.toViewModel(res);
  };

  public completeResearch = async (packageId: IPackage['packageId']): Promise<IGemology> => {
    const res = await this.services.gemology.finalize(packageId);

    return this.toViewModel(res);
  };

  @action
  public finalize = (): void => {
    this.errors = undefined;
    this.data = undefined;
  };

  private toViewModel(data: any = {}): IGemology {
    return {
      isWorkCompleted: data.isWorkCompleted ?? false,

      origin: data.origin ?? null,
      isFantasyShape: data.isFantasyShape ?? false,
      isFantasyColor: data.isFantasyColor ?? false,
      externalInfluence: data.externalInfluence ?? false,
      comment: data.comment ?? null,
      cut: data.cut ?? null,
      cutStyle: data.cutStyle ?? null,
      culet: data.culet ?? null,
      symmetry: data.symmetry ?? null,
      polish: data.polish ?? null,
      cutTo: data.cutTo ?? null,
      cutFrom: data.cutFrom ?? null,
      fluorescence: data.fluorescence ?? null,
      fantasyCutShapeRu: data.fantasyCutShapeRu ?? null,

      clarityRu: data.russian?.clarity ?? null,
      clarityRuFrom: data.russian?.clarityFrom ?? null,
      clarityRuTo: data.russian?.clarityTo ?? null,
      colorRu: data.russian?.color ?? null,
      fantasyColorRu: data.russian?.color ?? null,
      ordinalColorRuFrom: data.russian?.colorFrom ?? null,
      ordinalColorRuTo: data.russian?.colorTo ?? null,
      shapeRu: data.russian?.shape ?? null,

      clarityGia: data.worldwide?.clarity ?? null,
      clarityGiaFrom: data.worldwide?.clarityFrom ?? null,
      clarityGiaTo: data.worldwide?.clarityTo ?? null,

      fantasyColorGia: typeof data.worldwide?.color === 'object' ? (data.worldwide?.color ?? null) : null,
      ordinalColorGia: typeof data.worldwide?.color === 'string' ? data.worldwide?.color : null,
      ordinalColorGiaFrom: data.worldwide?.colorFrom ?? null,
      ordinalColorGiaTo: data.worldwide?.colorTo ?? null,
      shapeGia: data.worldwide?.shape ?? null,

      defects: new Set(data.defects ?? []),
    };
  }
}
