import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import OutlinedInput from '@mui/material/OutlinedInput';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button, Form, FormButtons, FormFieldType, IMetadataField } from '../../../../components';
import { CloseButton } from '../../../../components/buttons/close-button';
import { PurposeType } from '../../../../constants';
import { Stores } from '../../../../stores';
import { message, useConfirm } from '../../../../utils';
import { IEngraving } from '../../../../view-models';

export const Engraving: FC = observer(() => {
  const { engravingStore: store, packageStore } = useContext(MobXProviderContext) as Stores;
  const { confirm } = useConfirm();
  const { packageId } = useParams();

  const [isFinishing, setIsFinishing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm<Partial<IEngraving>>({
    values: {},
  });

  const totalEngravingText = watch('totalEngravingText');
  const isWorkCompleted = watch('isWorkCompleted');

  const loadFormData = useCallback(
    async (packageId?: string) => {
      if (packageId) {
        try {
          setIsLoading(true);
          const data = await store.fetch(packageId);
          reset(data);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [reset, packageId, setIsLoading]
  );

  useEffect(() => {
    loadFormData(packageId);
    return reset;
  }, [loadFormData, packageId, reset]);

  const copyToClipdoardClick = useCallback(async () => {
    if (totalEngravingText) {
      await navigator.clipboard.writeText(totalEngravingText);
      message.success('Скопировано', 500);
    }
  }, [totalEngravingText]);

  const metadata: IMetadataField[] = useMemo(
    () => [
      {
        name: 'totalEngravingText',
        label: 'Гравировка',
        type: FormFieldType.CUSTOM,
        template: (props) => (
          <OutlinedInput
            value={props.value}
            disabled
            endAdornment={<ContentCopyRoundedIcon className={'copy-button'} onClick={copyToClipdoardClick} />}
          />
        ),
      },
    ],
    [copyToClipdoardClick]
  );

  const onClickFinalize = useCallback(async () => {
    if (packageId) {
      try {
        setIsFinishing(true);
        const isConfirmed = await confirm({
          title: 'Подтверждение выполнения',
          content: <pre>Вы уверены, что хотите подтвердить выполнение работы?</pre>,
        });

        if (!isConfirmed) {
          return;
        }

        const completedResearch = await store.completeResearch(packageId);
        reset(completedResearch);
        // Для того чтобы обновить информацию (статус) в левом зелленом блоке
        packageStore.fetch(packageId, PurposeType.ForEngravingShooting);
        message.success('Выполнение работы подтверждено!');
      } finally {
        setIsFinishing(false);
      }
    }
  }, [packageId, store, reset]);

  return (
    <Form
      className={'worksheet__diagnostic'}
      metadata={metadata}
      control={control}
      errors={errors}
      disabled={isWorkCompleted}
      isLoading={isLoading}
    >
      <FormButtons>
        <CloseButton to={`../../${PurposeType.ForEngravingShooting}`} />
        {!isWorkCompleted && (
          <Button.Outlined disabled={!store.data} onClick={onClickFinalize} isLoading={isFinishing}>
            Подтвердить выполнение
          </Button.Outlined>
        )}
      </FormButtons>
    </Form>
  );
});
