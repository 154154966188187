import { DragEventHandler, FC, useCallback, useEffect } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { observer } from 'mobx-react';

import { IShooting } from '../../../../view-models';

import './files-list.css';

interface IFilesListProps {
  fileNames?: IShooting['otherMedia'];
  onDelete?: (file: string) => void;
  onClick?: (file: string) => void;
  onDrop?: DragEventHandler<HTMLDivElement>;
  disabled?: boolean;
}

export const FilesList: FC<IFilesListProps> = observer((props) => {
  const { disabled, fileNames, onDelete, onClick, onDrop } = props;

  const onDeleteClick = useCallback(
    (file: string) => {
      onDelete?.(file);
    },
    [onDelete]
  );

  const onFileNameClick = useCallback(
    (file: string) => {
      onClick?.(file);
    },
    [onDelete]
  );

  useEffect(() => {
    const eventListener = (evt: any) => {
      evt.preventDefault();
      return false;
    };

    if (onDrop) {
      ['dragover', 'drop'].forEach((eventName) => {
        document.addEventListener(eventName, eventListener);
      });
    }

    return () => {
      ['dragover', 'drop'].forEach((eventName) => {
        document.removeEventListener(eventName, eventListener);
      });
    };
  }, [onDrop]);

  return (
    <div className="files-list">
      <div className="files-list__files" onDrop={onDrop}>
        {fileNames?.map((file: string) => (
          <div key={file} className={'files-list__file'}>
            <span title={file} onClick={onFileNameClick.bind(this, file)}>
              {file}
            </span>
            {!disabled && <HighlightOffIcon onClick={onDeleteClick.bind(this, file)} />}
          </div>
        ))}
      </div>
      {!disabled && <span className="files-list__description">Перетащите файлы в поле выше или воспользуйтесь кнопкой</span>}
    </div>
  );
});
