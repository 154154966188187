import { FC, ReactNode, useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Stores } from '../../stores';

export interface IPrivateRouteProps {
  children?: ReactNode;
}

export const PrivateRoute: FC<IPrivateRouteProps> = observer((props) => {
  const { children } = props;
  const { authStore } = useContext(MobXProviderContext) as Stores;

  if (!authStore.isAuth) {
    window.location.assign(`/login`);
    return null;
  }

  if (!authStore.isAuth) {
    return <div>Страница не найдена</div>;
  }

  return <>{children}</>;
});
